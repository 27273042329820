"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  tabsname: "规格属性",
  buttontitle: "添加规格属性",
  inputplaceholder1: "请输入关键字",
  selectplaceholder1: "选择规格属性",
  buttonsousuo: "查询",
  buttonchongzhi: "重置",
  tablenamecn: "中文名称",
  tablenameEn: "英文名称",
  tabletype: "规格类型",
  slottype1: "颜色",
  slottype2: "尺寸",
  tablesort: "排序",
  tableremark: "备注",
  tableupdate: "更新人",
  tableupdatetime: "更新时间",
  formnamecn: "中文名称",
  formnameEn: "英文名称",
  formtype: "类型",
  formselectplaceholder: "请选择类型",
  formsort: "排序",
  formremark: "备注",
  forminputplaceholder: "请输入内容",
  buttonsave: "保 存",
  buttoncancel: "取 消",
  tabslog: "日志",
  formstartdate: "开始日期",
  formenddata: "结束日期",
  formiteminputplaceholder: "搜索日志内容",
  tablelog: "操作日志",
  tablelogname: "操作人",
  tablecreatetime: "操作时间",
  yanzhen1: "中文名称不能为空",
  yanzhen2: "英文名称不能为空",
  yanzhen3: "排序不能为空",
  yanzhen4: "类型不能为空",
  yanzhen5: "备注不能为空",
  buttonadd1: "添加属性",
  buttonadd2: "添加类型",
  buttonadd3: "添加鞋子类型",
  alertcont: "提交成功！",
  alerttitle: "提示"
};
var _default = languagePack;
exports.default = _default;