"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  name: '产品审核',
  label1: '审核情况:',
  label2: '审核未通过原因:',
  placeholder1: '开发原因',
  placeholder2: '编辑原因',
  placeholder3: '图片原因',
  save: '保存并继续编辑',
  clo: '关闭窗口',
  option1: '审核通过',
  option2: '审核不通过',
  option3: '审核中',
  option4: '草稿'
};
var _default = languagePack;
exports.default = _default;