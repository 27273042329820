"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCustomField = addCustomField;
exports.addSelect = addSelect;
exports.deleteCustomField = deleteCustomField;
exports.deleteSelect = deleteSelect;
exports.getCustomFieldList = getCustomFieldList;
exports.getSelectList = getSelectList;
exports.updateCustomField = updateCustomField;
exports.updateSelect = updateSelect;
var _request = _interopRequireDefault(require("@/utils/request"));
/* type: 1 线索分类
2 线索
3 商品
4 商品分类
5 客户
6 供应商
7 采购订单
8 销售订单
9 入库
10 出库
11 盘点
12 付款单
13 收款单 */

//自定义字段列表
function getCustomFieldList(type) {
  return (0, _request.default)({
    url: "/Customfield/".concat(type),
    method: 'get'
  });
}

// 添加自定义字段
function addCustomField(data) {
  return (0, _request.default)({
    url: '/Customfield',
    method: 'post',
    data: data
  });
}

// 修改自定义字段
function updateCustomField(type, data) {
  return (0, _request.default)({
    url: "/Customfield/".concat(type),
    method: 'put',
    data: data
  });
}

// 删除自定义字段
function deleteCustomField(type, data) {
  return (0, _request.default)({
    url: "/Customfield/".concat(type),
    method: 'delete',
    data: data
  });
}

// 1:线索来源 2:成交方式 3:采购类型 4:客户等级 15:销售类型
// 类型选择列表
function getSelectList(params) {
  // 不传type则获取全部类型
  return (0, _request.default)({
    url: '/TypeConfig',
    method: 'get',
    params: params
  });
}

//新增类型选择
function addSelect(data) {
  // 不传type则获取全部类型
  return (0, _request.default)({
    url: '/TypeConfig',
    method: 'post',
    data: data
  });
}

//修改类型选择
function updateSelect(id, data) {
  // 不传type则获取全部类型
  return (0, _request.default)({
    url: "/TypeConfig/".concat(id),
    method: 'put',
    data: data
  });
}

//删除类型选择
function deleteSelect(id) {
  // 不传type则获取全部类型
  return (0, _request.default)({
    url: "/TypeConfig/".concat(id),
    method: 'delete'
  });
}