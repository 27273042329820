"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  name: '采购产品包材',
  add: '添加',
  title1: '类型',
  title2: '型号',
  title3: '规格',
  title4: '价格',
  title5: '数量',
  title6: '操作',
  placeholder: '请输入内容',
  save: '保存并继续编辑',
  clo: '关闭窗口'
};
var _default = languagePack;
exports.default = _default;