"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  label1: '调用产品图片',
  label2: '产品SPU:',
  button: '调取图片',
  save: '保存并继续编辑',
  clo: '关闭窗口'
};
var _default = languagePack;
exports.default = _default;