"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkMail = checkMail;
exports.deleteMail = deleteMail;
exports.deleteMailBus = deleteMailBus;
exports.generateQuote = generateQuote;
exports.getCollect = getCollect;
exports.getDeletedList = getDeletedList;
exports.getDraftList = getDraftList;
exports.getReceiving = getReceiving;
exports.getSentList = getSentList;
exports.saveDraft = saveDraft;
exports.sendMail = sendMail;
exports.updateDraft = updateDraft;
var _request_module_ = _interopRequireDefault(require("@/utils/request_module_2"));
// 收件列表
function getReceiving(params) {
  return (0, _request_module_.default)({
    url: "/v1/mail/index",
    method: "get",
    params: params
  });
}

// 发送邮件
function sendMail(data) {
  return (0, _request_module_.default)({
    url: "/v1/mail/send",
    method: "post",
    data: data
  });
}

// 已发送列表
function getSentList(params) {
  return (0, _request_module_.default)({
    url: "/v1/mail/send",
    method: "get",
    params: params
  });
}

// 保存到草稿箱
function saveDraft(data) {
  return (0, _request_module_.default)({
    url: "/v1/mail/draft",
    method: "post",
    data: data
  });
}

// 修改草稿箱内容
function updateDraft(id, data) {
  return (0, _request_module_.default)({
    url: "/v1/mail/index/".concat(id),
    method: "put",
    data: data
  });
}

// 草稿箱列表
function getDraftList(params) {
  return (0, _request_module_.default)({
    url: "/v1/mail/draft",
    method: "get",
    params: params
  });
}

// 删除邮件
function deleteMail(id) {
  return (0, _request_module_.default)({
    url: "/v1/mail/index/".concat(id),
    method: "delete"
  });
}

// 已删除邮件列表
function getDeletedList(params) {
  return (0, _request_module_.default)({
    url: "/v1/mail/delete",
    method: "get",
    params: params
  });
}

// 收取邮件
function getCollect() {
  return (0, _request_module_.default)({
    url: "/v1/mail/collect",
    method: "get"
  });
}

// 检测邮箱
function checkMail(data) {
  return (0, _request_module_.default)({
    url: "/v1/mail/check",
    method: "post",
    data: data
  });
}

// 引用生成业务线索
function generateQuote(id, data) {
  return (0, _request_module_.default)({
    url: "/v1/mail/quote/".concat(id),
    method: "post",
    data: data
  });
}

// 删除邮件类型的业务线索
function deleteMailBus(id, data) {
  return (0, _request_module_.default)({
    url: "/v1/mail/del/".concat(id),
    method: "delete",
    data: data
  });
}