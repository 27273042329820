"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdminLogin = AdminLogin;
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.loginCallCenter = loginCallCenter;
exports.logout = logout;
exports.savePreference = savePreference;
var _request = _interopRequireDefault(require("@/utils/request"));
// 登录方法
function AdminLogin(username, password, code, uuid) {
  var data = {
    account: username,
    password: password,
    code: code,
    uuid: uuid
  };
  return (0, _request.default)({
    url: '/Login/testLogin',
    method: 'get',
    params: data
  });
}
function login(username, password, code, uuid) {
  var data = {
    account: username,
    password: password,
    code: code,
    uuid: uuid
  };
  return (0, _request.default)({
    url: '/Login/testLogin',
    method: 'post',
    data: data
  });
}

// 获取用户详细信息
function getInfo() {
  return (0, _request.default)({
    url: '/Menu/getInfo',
    method: 'get'
  });
}

// 获取通话参数
function loginCallCenter() {
  return (0, _request.default)({
    url: '/User/loginCallCenter',
    method: 'get'
  });
}

// 保存用户排序偏好
function savePreference(jsonArray) {
  return (0, _request.default)({
    url: 'Admin/create',
    method: 'get',
    params: {
      preference: jsonArray
    }
  });
}

// 退出方法
function logout() {
  return (0, _request.default)({
    url: '/logout',
    method: 'post'
  });
}

// 获取验证码
function getCodeImg() {
  return (0, _request.default)({
    url: '/captchaImage',
    method: 'get'
  });
}