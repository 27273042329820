"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title1: '上传产品图片',
  label1: '本地上传:',
  button1: '上传图片',
  span1: '点击“上传图片”按钮，请选择不超过2M的图片上传，支持',
  span2: '格式的图片。',
  label2: '链接上传:',
  button2: '上传',
  placeholder2: '请输入需上传图片的链接',
  placeholder3: '渠道选择',
  button3: '清空全部图片',
  span3: '图片尺寸',
  span4: '生成图片地址：',
  button4: '保存并继续编辑',
  button5: '关闭窗口',
  title2: "确定删除此照片, 是否继续?",
  title3: "提示",
  ok: '确定',
  cancel: '取消',
  title4: '删除成功!',
  title5: '已取消删除',
  msgsuccess: "操作成功"
};
var _default = languagePack;
exports.default = _default;