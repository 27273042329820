"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title1: '添加',
  title2: '批量删除',
  title3: '批量导入供应商',
  placeholder1: '供应商名称',
  placeholder2: '付款方式',
  placeholder3: '采购人员',
  placeholder4: '开发人员',
  placeholder5: '是否设置仓库',
  placeholder6: '仓库',
  button1: '查询',
  button2: '重置',
  placeholder7: '批量操作',
  button3: '提交',
  label1: '编号',
  label2: '供应商类型',
  span1: '采购类型：',
  span2: '付款方式：',
  span3: '是否黑名单：',
  span4: '是',
  span5: '否',
  label3: '供应商信息',
  span6: '名称：',
  span7: '发货地址：',
  span8: '地址：',
  span9: '仓库：',
  label4: '基础信息',
  span10: '自主上传新品：',
  span11: '送货清单：',
  span12: '支持贴标：',
  span13: '独立产品包装：',
  label5: '付款信息',
  span14: '账户名：',
  span15: '是否开票：',
  span16: '票据类型：',
  label6: '操作人员',
  span17: '添加：',
  span18: '开发：',
  span19: '采购：',
  label7: '操作',
  button4: '编辑',
  button5: '删除',
  label8: '基本信息',
  label9: '财务资料',
  label10: '联系信息',
  label11: '操作人员信息',
  label12: '文件资料存档',
  title4: '配置文件类型',
  title5: '添加',
  label13: '文件名称',
  label14: '文件说明',
  label15: '是否启用',
  label16: '操作人',
  label17: '操作时间',
  label18: '文件名称：',
  label19: '文件说明：',
  label20: '是否启用：',
  button6: '保存',
  button7: '取消',
  title6: '批量操作',
  label21: '选择采购人员',
  button8: '批量分配采购人员',
  label22: '选择开发人员',
  button9: '批量分配开发人员',
  label23: '选择采购经理',
  button10: '批量分配采购经理',
  label24: '选择供应商发货地',
  button11: '批量设置供应商发货地',
  label25: '选择发货仓库',
  button12: '批量设置发货仓库',
  label26: '设置黑名单',
  label27: '备注',
  button13: '批量设置黑名单',
  label28: '设置结算时间',
  placeholder28: '请选择',
  button14: '批量设置结算时间',
  label29: '付款方式',
  button15: '批量设置付款方式',
  label30: '启用状态',
  button16: '批量设置启用状态',
  filter1: '网络采购',
  filter2: '工厂采购',
  filter3: '市场采购',
  filter4: '自主生产',
  filter5: '未知采购类型',
  filter6: '现金付款',
  filter7: '网络在线付款',
  filter8: '转帐付款',
  filter9: '阿里账期付款',
  filter10: '增值税发票',
  filter11: '普通发票',
  filter12: '收据',
  filter13: '其他',
  data1: '现金付款',
  data2: '网络在线付款',
  data3: '转账付款',
  data4: '阿里账期付款',
  data5: '批量分配采购人',
  data6: '批量分配开发人',
  data7: '批量分配采购经理',
  data8: '批量设置供应商发货地',
  data9: '批量设置对应仓库',
  data10: '批量设置黑名单',
  data11: '批量设置结算时间',
  data12: '批量设置付款方式',
  data13: '批量设置启用状态',
  data14: '按下单时间',
  data15: '按销单时间',
  data16: '按入库时间',
  data17: '禁用',
  data18: '启用',
  data19: '供应商名称',
  data20: '供应商地址',
  data21: '收款证明图片附件',
  data22: '市场或工厂采纳建议需要上传收款证明图',
  data23: '吴姿',
  title7: '供应商信息新增',
  title8: '批量导入供应商',
  title9: '供应商信息修改',
  title10: '是否确认删除参数编号为',
  title11: '的数据',
  title12: '警告',
  title13: '删除成功',
  title14: '删除失败',
  title15: '已取消删除',
  title16: '编辑成功',
  title17: '编辑失败',
  title18: '新增成功',
  title19: '新增失败',
  ok: '确定',
  title20: '新增文件类型',
  title21: '请选择需要删除的编号后再进行删除操作！',
  title22: '提示',
  title23: '此操作将永久删除当前数据, 是否继续?',
  title24: '请选择需要批量操作的编号后，再进行批量操作！',
  title25: '请选择批量操作后，再提交！',
  title26: '新增联系人成功',
  title27: '新增联系人失败',
  title28: '删除联系人成功',
  title29: '删除联系人失败',
  title30: '修改联系人成功',
  title31: '修改联系人失败',
  title32: '请选择采购人员后再进行分配操作',
  title33: '批量分配采购客服成功',
  title34: '批量分配采购客服失败',
  title35: '请选择开发人员后再进行分配操作',
  title36: '批量分配开发人员成功!',
  title37: '批量分配开发人员失败!',
  title38: '请选择采购经理后再进行分配操作',
  title39: '批量分配采购经理成功!',
  title40: '批量分配采购经理失败!',
  title41: '请选择省市后再进行分配操作',
  title42: '批量设置供应商发货地成功!',
  title43: '批量设置供应商发货地失败!',
  title44: '请选择发货仓库后再进行批量操作',
  title45: '批量设置发货仓库成功!',
  title46: '批量设置发货仓库失败!',
  title47: '请输入备注',
  title48: '批量设置黑名单成功!',
  title49: '批量设置黑名单失败!',
  title50: '请选择结算时间后再进行批量操作',
  title51: '批量设置结算时间成功!',
  title52: '批量设置结算时间失败!',
  title53: '请选择付款方式后再进行批量操作',
  title54: '批量设置付款方式成功!',
  title55: '批量设置付款方式失败!',
  title56: '请选择启用状态后再进行批量操作',
  title57: '批量设置启用状态成功!',
  title58: '批量设置启用状态失败!'
};
var _default = languagePack;
exports.default = _default;