"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  buttonadd: "添加单位",
  buttonlog: "日志",
  inputplaceholder1: "关键字",
  inputplaceholder2: "内置单位",
  buttonsousuo: "查询",
  buttonchongzhi: "重置",
  tablenamecn: "中文名称",
  tablenameen: "英文名称",
  tablename: "全名",
  tableactive: "是否激活",
  tablesort: "排序",
  tableremark: "备注",
  tableupdatename: "更新人",
  tableupdatetime: "更新时间",
  formitemnamecn: "中文名称:",
  inputplaceholder3: "请输入中文名称",
  formitemnameEn: "英文名称:",
  inputplaceholder4: "请输入英文名称",
  formitemname: "全名:",
  inputplaceholder5: "请输入全名",
  formitemtype: "单位类型:",
  formitemsort: "排序:",
  inputplaceholder6: "请输入排序",
  formitemjihuo: "是否激活:",
  radio1: "是",
  radio2: "否",
  formitembeizhu: "备注:",
  inputplaceholder7: "请输入备注",
  buttonsave: "保存",
  buttonquxiao: "取消",
  tablelog: "操作日志",
  tablecaozuo: "操作人",
  tablecreatetime: "操作时间",
  yanzhen1: "请输入中文名称",
  yanzhen2: "请输入英文名称",
  yanzhen3: "请输入全名",
  yanzhen4: "请输入单位类型",
  yanzhen5: "请输入排序",
  yanzhen6: "请输入是激活",
  yanzhen7: "请输入备注",
  buttonadddanwei: "添加单位",
  alertneirong: "提交成功！",
  alerttitle: "提示",
  logtitle: "日志"
};
var _default = languagePack;
exports.default = _default;