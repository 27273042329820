"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  table: '联系信息',
  title1: '添加联系人方式',
  label1: '默认联系人',
  label2: '联系人',
  label3: '职位',
  label4: '电话',
  label5: '传真',
  label6: '手机',
  label7: '邮箱',
  label8: '旺旺',
  label9: '对应用户名',
  label10: '操作',
  button1: '修改',
  button2: '删除',
  checkbox: '默认联系人',
  button3: '保 存',
  button4: '取 消',
  message1: '请输入联系人姓名',
  message2: '请输入手机号',
  message3: '请选择对应用户名',
  title2: '添加联系人方式',
  title3: '修改联系人方式',
  message4: '设置成功!',
  message5: '设置失败!'
};
var _default = languagePack;
exports.default = _default;