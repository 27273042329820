"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _vue = _interopRequireDefault(require("vue"));
// directive.js

_vue.default.directive('draggable', {
  bind: function bind(el, binding) {
    var header = el;
    var startX = 0;
    var startY = 0;
    var offsetX = 0;
    var offsetY = 0;
    var isDragging = false;
    header.style.cursor = 'move';
    header.addEventListener('mousedown', function (e) {
      startX = e.clientX;
      startY = e.clientY;
      offsetX = el.parentNode.offsetLeft;
      offsetY = el.parentNode.offsetTop;
      isDragging = true;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    });
    function onMouseMove(e) {
      if (!isDragging) return;
      var dx = e.clientX - startX;
      var dy = e.clientY - startY;
      el.parentNode.style.left = "".concat(offsetX + dx, "px");
      el.parentNode.style.top = "".concat(offsetY + dy, "px");
    }
    function onMouseUp() {
      isDragging = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
  }
});