"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _app = _interopRequireDefault(require("./modules/app"));
var _user = _interopRequireDefault(require("./modules/user"));
var _tagsView = _interopRequireDefault(require("./modules/tagsView"));
var _permission = _interopRequireDefault(require("./modules/permission"));
var _settings = _interopRequireDefault(require("./modules/settings"));
var _repair = _interopRequireDefault(require("./modules/repair"));
var _mail = _interopRequireDefault(require("./modules/mail"));
var _client = _interopRequireDefault(require("./modules/client"));
var _business = _interopRequireDefault(require("./modules/business"));
var _sale = _interopRequireDefault(require("./modules/sale"));
var _data = _interopRequireDefault(require("./modules/data"));
var _getters = _interopRequireDefault(require("./getters"));
var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));
var _elementUi = require("element-ui");
var state = {
  topNavState: 'home',
  leftNavState: 'home',
  showTip: true,
  devMode: false
};
var mutations = {
  SET_TIP_SHOW: function SET_TIP_SHOW(state) {
    state.showTip = !state.showTip;
  },
  SET_DEV: function SET_DEV(state) {
    state.devMode = !state.devMode;
    if (state.devMode) {
      // true
      localStorage.setItem('baseUrl1', 'http://192.168.1.254:8890/api/');
      localStorage.setItem('baseUrl2', 'http://192.168.1.254:8892/api/');
    } else {
      localStorage.removeItem('baseUrl1');
      localStorage.removeItem('baseUrl2');
    }
  }
};
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    user: _user.default,
    tagsView: _tagsView.default,
    permission: _permission.default,
    settings: _settings.default,
    repair: _repair.default,
    mail: _mail.default,
    client: _client.default,
    business: _business.default,
    sale: _sale.default,
    data: _data.default
  },
  plugins: [(0, _vuexPersistedstate.default)({
    paths: ['user.sort', 'showTip', 'devMode', 'user/userInfo', 'mail.mailList', 'mail.mailData', 'mail.draftData', 'client.gradeColor', 'business.statusColor']
  })],
  getters: _getters.default,
  mutations: mutations,
  state: state
});
var _default = store;
exports.default = _default;