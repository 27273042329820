"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title1: '产品报关信息',
  label1: '产品报关英文名:',
  label2: '产品报关中文名:',
  label3: '产品用途:',
  label4: '报关材料:',
  label5: '海关编码:',
  checkbox1: '是否审核',
  button1: '查询海关编码',
  span1: '注：默认填写产品类别海关编码，若手动修改，平台将使用您手动修改后的海关编码',
  label6: '退税率:',
  span2: '支持小数点后两位',
  label7: '报关价:',
  span3: '默认是供货价的40%，转换为美元，保留小数点后两位',
  label8: '报关单位:',
  label9: '申报要素:',
  span4: '注：若有多个申报要素，以“|”分隔',
  checkbox2: '覆盖其他子体申报要素',
  label10: '欧洲报关链接:',
  button2: '打开报关链接',
  label11: '欧洲报关链接价格:',
  span11: '默认是报关价按汇率转换为欧元，保留小数点后两位；若低于10欧，默认加2欧作为运费',
  label12: '报关信息同步子体:',
  span12: '已编辑和审核通过的子体编辑信息不会覆盖',
  save: '保存并继续编辑',
  cancel: '关闭窗口',
  button3: "同步翻译英文名",
  checkbox3: "是否同步",
  option1: "否",
  option2: "是",
  placeholder1: "请输入产品报关英文名",
  placeholder2: "请输入产品报关中文名",
  placeholder3: "请输入海关编码"
};
var _default = languagePack;
exports.default = _default;