"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _rtc = _interopRequireDefault(require("@/components/rtc"));
//
//
//
//
//
//
//
var _default = {
  name: 'App',
  components: {
    Rtc: _rtc.default
  },
  watch: {},
  methods: {},
  created: function created() {
    var _this = this;
    if (window.systemtimer) {
      clearInterval(window.systemtimer);
      window.systemtimer = setInterval(function () {
        _this.$store.commit('app/SET_SYSTEM_TIME');
      }, 1000);
    } else {
      window.systemtimer = setInterval(function () {
        _this.$store.commit('app/SET_SYSTEM_TIME');
      }, 1000);
    }
  }
};
exports.default = _default;