"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logo = _interopRequireDefault(require("@/assets/logo/logo.png"));
var _variables2 = _interopRequireDefault(require("@/assets/styles/variables.scss"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    variables: function variables() {
      return _variables2.default;
    },
    sideTheme: function sideTheme() {
      return this.$store.state.settings.sideTheme;
    }
  },
  data: function data() {
    return {
      title: '店掌宝ERP',
      logo: _logo.default
    };
  }
};
exports.default = _default;