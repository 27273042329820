"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addTransport = addTransport;
exports.deleteTransport = deleteTransport;
exports.getTransport = getTransport;
exports.updateTransport = updateTransport;
var _request = _interopRequireDefault(require("@/utils/request"));
// 日程列表
function getTransport(params) {
  return (0, _request.default)({
    url: "Transport",
    method: "get",
    params: params
  });
}

// 新增日程
function addTransport(data) {
  return (0, _request.default)({
    url: "Transport",
    method: "post",
    data: data
  });
}

// 删除日程
function deleteTransport(id) {
  return (0, _request.default)({
    url: "Transport/".concat(id),
    method: "delete"
  });
}

// 更新日程
function updateTransport(id, data) {
  return (0, _request.default)({
    url: "Transport/".concat(id),
    method: "put",
    data: data
  });
}