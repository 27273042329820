"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  productsku: '产品SKU13456245125',
  productname: '仙女与蒲公英共舞雕像'
};
var _default = languagePack;
exports.default = _default;