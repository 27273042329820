"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  selectplaceholder: "请选择",
  inputplaceholder: "请输入搜索的内容",
  buttonsousuo: "查询",
  buttonqueding: "确定",
  formitemlabel: "选择组合:",
  radiolabel1: "选择包含sku",
  radiolabel2: "选择不包含sku",
  tablelabel1: "产品SKU",
  tablelabel2: "产品名称",
  tablelabel3: "颜色",
  tablelabel4: "尺寸",
  tablelabel5: "是否有组合",
  tablelabel6: "操作",
  tablelabel7: "图片",
  buttondel: "删除",
  guolv1: "是",
  guolv2: "否",
  confirmneirong: "此操作将永久删除该文件, 是否继续?",
  confirmtitle: "提示",
  confirmqueding: "确定",
  confirmquxiao: "取消",
  msgdel: "删除成功!",
  msgdelquxiao: "已取消删除",
  zuhechenggong: "组合成功",
  option1: "产品SKU",
  option2: "产品SPU",
  option3: "商品中文名"
};
var _default = languagePack;
exports.default = _default;