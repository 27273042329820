"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  repair: {}
};
var mutations = {
  updateStorageProject: function updateStorageProject(state, value) {
    var rid = value.rid,
      arr = value.arr;
    if (!rid) {
      return;
    }
    state.repair[rid] = _.cloneDeep(arr);
  },
  clearStorageProject: function clearStorageProject(state, value) {
    state.repair = {};
  }
};
var actions = {};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;