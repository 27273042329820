"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  label1: '审核情况',
  label2: '审核未通过原因',
  placeholder1: '开发原因',
  placeholder2: '编辑原因',
  placeholder3: '图片原因'
};
var _default = languagePack;
exports.default = _default;