"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  id2: '供应商',
  id4: '库存数(下阶段开发)',
  id5: '产品来源',
  id5_1: '系统采集',
  id5_2: '开发采集',
  id5_3: '新样品',
  id5_4: '供应商后台',
  id6: '开发类型',
  id6_1: '自主开发',
  id6_2: '供应商后台',
  id6_3: '指定开发',
  id7: '图片来源',
  id7_1: '采集图',
  id7_2: '供应商提供图',
  id7_3: '自己拍照图',
  id8: '是否有图',
  id8_1: '有图',
  id8_2: '无图',
  id10: '上架状态(下阶段开发)',
  id11: '物流属性',
  id12: '强电插头',
  id12_1: '无',
  id12_2: '有',
  id14: '售完自动下架(下阶段开发)',
  id15: '已上架平台(下阶段开发)',
  id18: '开发人员',
  id19: '产品状态',
  id19_1: '在售',
  id19_2: '滞销',
  id19_3: '停产',
  id19_4: '锁定',
  id19_5: '暂时缺货',
  id19_6: '清库'
};
var _default = languagePack;
exports.default = _default;