"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OutsourcingGoodsLogsUpdate = OutsourcingGoodsLogsUpdate;
exports.OutsourcingOutLog = OutsourcingOutLog;
exports.addContact = addContact;
exports.addOutsourcingGoodsLog = addOutsourcingGoodsLog;
exports.addOutsourcingOrder = addOutsourcingOrder;
exports.addPurchaseChargeBack = addPurchaseChargeBack;
exports.addPurchaseOrder = addPurchaseOrder;
exports.addPurchaseOrderGoods = addPurchaseOrderGoods;
exports.addSettlement = addSettlement;
exports.addSupplier = addSupplier;
exports.deleteContact = deleteContact;
exports.deleteOutsourcingGoodsLog = deleteOutsourcingGoodsLog;
exports.deleteOutsourcingOrder = deleteOutsourcingOrder;
exports.deleteOutsourcingOutLog = deleteOutsourcingOutLog;
exports.deletePurchaseChargeBack = deletePurchaseChargeBack;
exports.deletePurchaseOrder = deletePurchaseOrder;
exports.deletePurchaseOrderGoods = deletePurchaseOrderGoods;
exports.deleteSettlement = deleteSettlement;
exports.deleteSupplier = deleteSupplier;
exports.editContact = editContact;
exports.editSettlement = editSettlement;
exports.getOutsourcingGoodsLog = getOutsourcingGoodsLog;
exports.getOutsourcingGoodsLogs = getOutsourcingGoodsLogs;
exports.getOutsourcingOrder = getOutsourcingOrder;
exports.getOutsourcingOrderDetails = getOutsourcingOrderDetails;
exports.getOutsourcingOutLog = getOutsourcingOutLog;
exports.getPurchaseChargeBack = getPurchaseChargeBack;
exports.getPurchaseChargeBackDetail = getPurchaseChargeBackDetail;
exports.getPurchaseChargeBackGoods = getPurchaseChargeBackGoods;
exports.getPurchaseOrder = getPurchaseOrder;
exports.getPurchaseOrderDetail = getPurchaseOrderDetail;
exports.getPurchaseOrderGoods = getPurchaseOrderGoods;
exports.getSupplierDetail = getSupplierDetail;
exports.getSupplierList = getSupplierList;
exports.isBackDetail = isBackDetail;
exports.updateOutsourcingGoodsLog = updateOutsourcingGoodsLog;
exports.updateOutsourcingOrder = updateOutsourcingOrder;
exports.updateOutsourcingOutLog = updateOutsourcingOutLog;
exports.updatePurchaseChargeBack = updatePurchaseChargeBack;
exports.updatePurchaseOrder = updatePurchaseOrder;
exports.updatePurchaseOrderGoods = updatePurchaseOrderGoods;
exports.updateSupplier = updateSupplier;
var _request = _interopRequireDefault(require("@/utils/request"));
// 供应商列表
function getSupplierList(params) {
  return (0, _request.default)({
    url: '/Supplier',
    method: 'get',
    params: params
  });
}

// 添加供应商
function addSupplier(data) {
  return (0, _request.default)({
    url: '/Supplier',
    method: 'post',
    data: data
  });
}

// 供应商详情
function getSupplierDetail(id) {
  return (0, _request.default)({
    url: "/Supplier/".concat(id),
    method: 'get'
  });
}

// 删除供应商
function deleteSupplier(id) {
  return (0, _request.default)({
    url: "/Supplier/".concat(id),
    method: 'delete'
  });
}

// 修改供应商
function updateSupplier(id, data) {
  return (0, _request.default)({
    url: "/Supplier/".concat(id),
    method: 'put',
    data: data
  });
}

// 新增联系人(编辑供应商时才使用)
function addContact(data) {
  return (0, _request.default)({
    url: '/SupplierDetail',
    method: 'post',
    data: data
  });
}

// 编辑联系人(编辑供应商时才使用)
function editContact(id, data) {
  return (0, _request.default)({
    url: "/SupplierDetail/".concat(id),
    method: 'put',
    data: data
  });
}

// 删除联系人(编辑供应商时才使用)
function deleteContact(id) {
  return (0, _request.default)({
    url: "/SupplierDetail/".concat(id),
    method: 'delete'
  });
}

// 采购订单列表
function getPurchaseOrder(params) {
  return (0, _request.default)({
    url: '/PurchaseOrder',
    method: 'get',
    params: params
  });
}

// 添加采购订单
function addPurchaseOrder(data) {
  return (0, _request.default)({
    url: '/PurchaseOrder',
    method: 'post',
    data: data
  });
}

// 修改采购订单
function updatePurchaseOrder(id, data) {
  return (0, _request.default)({
    url: "/PurchaseOrder/".concat(id),
    method: 'put',
    data: data
  });
}

// 删除采购订单
function deletePurchaseOrder(id) {
  return (0, _request.default)({
    url: "/PurchaseOrder/".concat(id),
    method: 'delete'
  });
}

// 采购订单详情
function getPurchaseOrderDetail(id) {
  return (0, _request.default)({
    url: "/PurchaseOrder/".concat(id),
    method: 'get'
  });
}

// 采购订单商品列表
function getPurchaseOrderGoods(params) {
  return (0, _request.default)({
    url: '/PurchasesGoodsLog',
    method: 'get',
    params: params
  });
}

// 采购订单添加商品 PurchasesGoodsLog
function addPurchaseOrderGoods(data) {
  return (0, _request.default)({
    url: '/PurchasesGoodsLog',
    method: 'post',
    data: data
  });
}

// 采购订单修改商品 PurchasesGoodsLog
function updatePurchaseOrderGoods(id, data) {
  return (0, _request.default)({
    url: "/PurchasesGoodsLog/".concat(id),
    method: 'put',
    data: data
  });
}

// 采购订单删除商品 PurchasesGoodsLog
function deletePurchaseOrderGoods(id) {
  return (0, _request.default)({
    url: "/PurchasesGoodsLog/".concat(id),
    method: 'delete'
  });
}

// 采退订单列表
function getPurchaseChargeBack(params) {
  return (0, _request.default)({
    url: '/PurchaseChargeBack',
    method: 'get',
    params: params
  });
}

//采退订单商品订单 PurchaseChargeBack
function getPurchaseChargeBackGoods(id) {
  return (0, _request.default)({
    url: "/PurchaseChargeBack/".concat(id, "/edit"),
    method: 'get'
  });
}

// 新增采退订单
function addPurchaseChargeBack(data) {
  return (0, _request.default)({
    url: '/PurchaseChargeBack',
    method: 'post',
    data: data
  });
}

// 采退订单详情
function getPurchaseChargeBackDetail(id) {
  return (0, _request.default)({
    url: "/PurchaseChargeBack/".concat(id),
    method: 'get'
  });
}

// 编辑采退订单
function updatePurchaseChargeBack(id, data) {
  return (0, _request.default)({
    url: "/PurchaseChargeBack/".concat(id),
    method: 'put',
    data: data
  });
}

// 删除采退订单
function deletePurchaseChargeBack(id) {
  return (0, _request.default)({
    url: "/PurchaseChargeBack/".concat(id),
    method: 'delete'
  });
}

// 委外订单 OutsourcingOrder
function getOutsourcingOrder(params) {
  return (0, _request.default)({
    url: '/OutsourcingOrder',
    method: 'get',
    params: params
  });
}

// 委外订单商品列表
function getOutsourcingGoodsLog(params) {
  return (0, _request.default)({
    url: '/OutsourcingGoodsLog',
    method: 'get',
    params: params
  });
}

// 添加委外订单
function addOutsourcingOrder(data) {
  return (0, _request.default)({
    url: '/OutsourcingOrder',
    method: 'post',
    data: data
  });
}

// 委外订单添加商品 PurchasesGoodsLog
function addOutsourcingGoodsLog(data) {
  return (0, _request.default)({
    url: '/OutsourcingGoodsLog',
    method: 'post',
    data: data
  });
}

// 采购委外修改商品 PurchasesGoodsLog
function updateOutsourcingGoodsLog(id, data) {
  return (0, _request.default)({
    url: "/OutsourcingGoodsLog/".concat(id),
    method: 'put',
    data: data
  });
}

// 委外订单详情
function getOutsourcingOrderDetails(id) {
  return (0, _request.default)({
    url: "/OutsourcingOrder/".concat(id),
    method: 'get'
  });
}

// 采购委外删除商品 PurchasesGoodsLog
function deleteOutsourcingGoodsLog(id) {
  return (0, _request.default)({
    url: "/OutsourcingGoodsLog/".concat(id),
    method: 'delete'
  });
}

// 修改委外订单
function updateOutsourcingOrder(id, data) {
  return (0, _request.default)({
    url: "/OutsourcingOrder/".concat(id),
    method: 'put',
    data: data
  });
}

// 删除委外订单
function deleteOutsourcingOrder(id) {
  return (0, _request.default)({
    url: "/OutsourcingOrder/".concat(id),
    method: 'delete'
  });
}

// 委外订单添加出库商品
function OutsourcingOutLog(data) {
  return (0, _request.default)({
    url: '/OutsourcingOutLog',
    method: 'post',
    data: data
  });
}

// 委外订单出库商品列表
function getOutsourcingGoodsLogs(params) {
  return (0, _request.default)({
    url: '/OutsourcingGoodsLog',
    method: 'get',
    params: params
  });
}

// 委外订单修改商品列表
function OutsourcingGoodsLogsUpdate(id, data) {
  return (0, _request.default)({
    url: "/OutsourcingGoodsLog/".concat(id),
    method: 'PUT',
    data: data
  });
}

// 出库商品列表
function getOutsourcingOutLog(params) {
  return (0, _request.default)({
    url: '/OutsourcingOutLog',
    method: 'get',
    params: params
  });
}

// 修改出库商品
function updateOutsourcingOutLog(id, data) {
  return (0, _request.default)({
    url: "/OutsourcingOutLog/".concat(id),
    method: 'put',
    data: data
  });
}

// 删除出库商品
function deleteOutsourcingOutLog(id) {
  return (0, _request.default)({
    url: "/OutsourcingOutLog/".concat(id),
    method: 'delete'
  });
}

// 是否退单
function isBackDetail(params) {
  return (0, _request.default)({
    url: "/purchaseOrder/backDetail",
    method: 'get',
    params: params
  });
}

// 新增结算信息(编辑供应商时才使用)
function addSettlement(data) {
  return (0, _request.default)({
    url: '/Settlement',
    method: 'post',
    data: data
  });
}

// 编辑结算信息(编辑供应商时才使用)
function editSettlement(id, data) {
  return (0, _request.default)({
    url: "/Settlement/".concat(id),
    method: 'put',
    data: data
  });
}

// 删除结算信息(编辑供应商时才使用)
function deleteSettlement(id) {
  return (0, _request.default)({
    url: "/Settlement/".concat(id),
    method: 'delete'
  });
}