"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  label1: '操作时间：',
  start: '开始日期',
  end: '结束日期',
  label2: '操作内容',
  placeholder: '请输入内容',
  title: '查询',
  label3: '操作人',
  label4: '操作内容'
};
var _default = languagePack;
exports.default = _default;