var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTip
    ? _c(
        "div",
        { staticClass: "hint mg-b-20" },
        [
          _c(
            "transition-group",
            { attrs: { name: "slide-fade" } },
            _vm._l(_vm.text, function (x) {
              return _c("p", { key: x }, [
                _c("i", {
                  staticClass: "el-icon-info",
                  staticStyle: { color: "#e6a23c", "margin-right": "5px" },
                }),
                _vm._v(_vm._s(x) + "\n    "),
              ])
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }