"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title1: '下载',
  span1: '下载标准格式表格（更新日期 2021-04-27）',
  button1: '下载表格',
  span2: '（如果点击后没有反应，请检查浏览器是否屏蔽了弹窗功能）',
  title2: '上传',
  span3: '请确认无误后上传',
  button2: '上传文件',
  span4: '不要随意修改Excel模板格式，否则可能导致批量操作失败',
  span5: '请完全依照下载模板的格式填入信息，不要使用函数、算法等操作Excel',
  span6: '否则可能导致读取的数值和实际不符',
  title3: '分类批量导入',
  message1: '上传文件只能是 excel/xls 格式!'
};
var _default = languagePack;
exports.default = _default;