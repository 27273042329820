"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var productModuleFiles = require.context('./product-module', true, /.js$/);
var arr = [];
var productModule = productModuleFiles.keys().reduce(function (modules, modulePath) {
  var path = modulePath.substr(modulePath.lastIndexOf('/') + 1);
  arr.push(modulePath);
  var moduleName = path.substring(0, path.indexOf(".")).replace(/^.\/(.*)\.js/, '$1');
  var value = productModuleFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
console.log('productModule', productModule);
var allModule = (0, _objectSpread2.default)({}, productModule);
var _default = allModule;
exports.default = _default;