"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mail = require("@/api_module_2/mail");
var state = {
  mailList: null,
  //保存收件列表
  mailData: null,
  //查看的邮件详情(收件箱，已发送，已删除)
  draftData: null //查看的邮件详情(草稿箱)
};

var mutations = {
  // 保存邮件列表
  saveMailList: function saveMailList(state, mailList) {
    state.mailList = mailList;
  },
  // 保存邮件详情(收件箱，已发送，已删除)
  saveMailData: function saveMailData(state, mailData) {
    state.mailData = mailData;
  },
  // 保存邮件详情(草稿箱)
  saveDraftData: function saveDraftData(state, mailData) {
    state.draftData = mailData;
  },
  // 清空存储的邮件详情
  clearMailData: function clearMailData(state) {
    state.mailList = null;
    state.mailData = null;
    state.draftData = null;
  }
};
var actions = {
  // 收取邮件
  getCollectList: function getCollectList() {
    return new Promise(function (resolve, reject) {
      (0, _mail.getCollect)().then(function (res) {
        if (res.data > 0) {
          resolve();
        }
      }).catch(function (err) {
        console.error(err);
        reject();
      });
    });
  },
  // 请求邮件列表
  getMailList: function getMailList(_ref, params) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _mail.getReceiving)(params).then(function (res) {
        commit('saveMailList', res.data);
        resolve();
      }).catch(function (err) {
        console.error(err);
        reject();
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;