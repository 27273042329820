"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  proname: "名称"
};
var _default = languagePack;
exports.default = _default;