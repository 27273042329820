"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _customField = require("@/api/customField");
var actions = {
  getPrimaryCustomField: function getPrimaryCustomField(_ref, type) {
    var commit = _ref.commit;
    console.log("%c Line:5 🍏 type", "color:#7f2b82", type);
    return new Promise(function (resolve, reject) {
      (0, _customField.getSelectList)({
        "type": type
      }).then(function (res) {
        try {
          resolve(res.data[0].value);
        } catch (err) {
          reject(err);
        }
      }).catch(function (err) {
        console.error(err);
        reject(err);
      });
    });
  }
};
var _default = {
  namespaced: true,
  actions: actions
};
exports.default = _default;