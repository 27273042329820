"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  buttontitle: "添加新规则",
  placeholder1: "请输入规则名称",
  placeholder2: "请选择产品分类",
  buttonsousuo: "查询",
  buttonchongzhi: "重置",
  placeholder3: "批量操作",
  buttontijiao: "提交",
  tablelabel1: "规则名称",
  tablelabel2: "产品分类",
  tablelabel3: "规则公式",
  tablelabel4: "是否激活",
  tablelabel5: "操作记录",
  tablelabel6: "操作",
  buttonchakan: "查看",
  dialogtitle: "添加新规则",
  formitemlabel1: "规则名称",
  placeholder4: "请输入内容",
  formitemlabel2: "产品分类",
  placeholder5: "请选择",
  span1: "规则公式：",
  span2: "{#产品大分类｜产品二级分类-产品三级分类-添加顺序#}",
  span3: "注：位数必须为正整数",
  placeholder6: "请输入内容",
  buttonname: "开始测试",
  span4: "（请保存后再测试）",
  buttonsave: "保存",
  checkbox1: "是否激活",
  dialogtitle2: "查看操作记录",
  tabletitle1: "操作人",
  tabletitle2: "操作内容",
  tabletitle3: "操作时间",
  yanzhen1: "规则名称不能为空",
  yanzhen2: "产品分类不能为空",
  yanzhen3: "规则公式不能为空",
  yanzhen4: "规则说明不能为空",
  guolv1: "已激活",
  guolv2: "未激活",
  guolv3: "已停用",
  dialogtitle3: "修改规则",
  confirmdel: "是否确认删除参数编号为",
  confirmdel2: "的数据项?",
  confirmtitle: "警告",
  confirmbutton1: "确定",
  confirmbutton2: "取消",
  msgdel: "删除成功",
  dialogtitle4: "添加新规则",
  alertneirong: "提交成功！",
  alerttitle: "提示",
  confirmneirong: "是否确认导出所有参数数据项?"
};
var _default = languagePack;
exports.default = _default;