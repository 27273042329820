"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
require("core-js/modules/web.dom.iterable");
var _router = require("@/router");
var _menu = require("@/api/menu");
var _index = _interopRequireDefault(require("@/layout/index"));
var _ParentView = _interopRequireDefault(require("@/components/ParentView"));
var permission = {
  state: {
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router.constantRoutes.concat(routes);
    },
    SET_DEFAULT_ROUTES: function SET_DEFAULT_ROUTES(state, routes) {
      state.defaultRoutes = _router.constantRoutes.concat(routes);
    },
    SET_TOPBAR_ROUTES: function SET_TOPBAR_ROUTES(state, routes) {
      // 顶部导航菜单默认添加统计报表栏指向首页
      var index = [{
        path: 'index',
        meta: {
          title: '首页',
          icon: 'home 375'
        }
      }];
      state.topbarRouters = index.concat(routes);
    },
    SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routes) {
      state.sidebarRouters = routes;
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      console.log('sss,daozhe', data);
      var uid = data.id;
      return new Promise(function (resolve, reject) {
        // 向后端请求路由数据
        (0, _menu.getRouters)().then(function (res) {
          if (uid == 1) {
            res.data = res.data.filter(function (e) {
              return e.id == 1;
            });
          }
          console.log('res', res);
          var la = JSON.parse(JSON.stringify(res));
          var fa = new Array(la.data[0]);
          var newRes = res.data.map(function (e, idx) {
            return {
              alwaysShow: e.alwaysShow === false ? false : true,
              children: MapChildren(e.children),
              component: "Layout",
              hidden: e.alwaysShow === true ? true : false,
              meta: {
                title: e.menu_name,
                is_list: e.is_list,
                id: e.id,
                icon: e.menu_logo,
                noCache: false,
                link: null
              },
              name: e.power_mark,
              path: e.front_path,
              redirect: "noRedirect"
            };
          });
          console.log('newRes', newRes);
          var sdata = JSON.parse(JSON.stringify(newRes));
          var rdata = JSON.parse(JSON.stringify(newRes));
          var sidebarRoutes = filterAsyncRouter(sdata);
          var rewriteRoutes = filterAsyncRouter(rdata, false, true);
          rewriteRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          commit('SET_ROUTES', rewriteRoutes);
          commit('SET_SIDEBAR_ROUTERS', _router.constantRoutes.concat(sidebarRoutes));
          commit('SET_DEFAULT_ROUTES', sidebarRoutes);
          commit('SET_TOPBAR_ROUTES', sidebarRoutes);
          resolve(rewriteRoutes);
        });
      });
    }
  }
};

// todo:需要改为递归
function MapChildren(routes) {
  if (!routes) {
    return [];
  }
  var newRoutes = JSON.parse(JSON.stringify(routes));
  var vRoutes = [];
  newRoutes.forEach(function (x) {
    var obj = {};
    var str = x.front_path;
    var index = str.lastIndexOf("\/");
    str = str.substring(index + 1, str.length);
    obj.alwaysShow = true;
    obj.component = "ParentView";
    obj.hidden = x.hidden === 0 ? false : true;
    obj.meta = {
      title: x.menu_name,
      is_list: x.is_list,
      id: x.id,
      icon: x.menu_logo,
      noCache: false,
      link: null
    };
    obj.name = str;
    obj.path = str;
    obj.redirect = "noRedirect";
    if (Array.isArray(x.children) && x.children.length > 0) {
      obj.children = [];
      x.children.forEach(function (y) {
        var obj2 = {};
        var str = y.front_path;
        var index = str.lastIndexOf("\/");
        str = str.substring(index + 1, str.length);
        obj2.component = y.front_path.slice(1);
        obj2.hidden = y.hidden === 0 ? false : true;
        obj2.meta = {
          title: y.menu_name,
          is_list: y.is_list,
          id: y.id,
          icon: y.menu_logo,
          noCache: false,
          link: null
        };
        obj2.name = str;
        obj2.path = str;
        obj.children.push(obj2);
        if (y.children) {
          obj2.children = y.children;
        }
      });
    }
    vRoutes.push(obj);
  });
  return vRoutes;
}
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return asyncRouterMap.filter(function (route) {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = _index.default;
      } else if (route.component === 'ParentView') {
        route.component = _ParentView.default;
      } else if (route.component === 'InnerLink') {
        route.component = InnerLink;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route['children'];
      delete route['redirect'];
    }
    return true;
  });
}
function filterChildren(childrenMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var children = [];
  childrenMap.forEach(function (el, index) {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(function (c) {
          c.path = el.path + '/' + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path;
    }
    children = children.concat(el);
  });
  return children;
}
var loadView = function loadView(view) {
  // console.log('%c 🍣 view: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', view);
  if (process.env.NODE_ENV === 'development') {
    return function (resolve) {
      return require(["@/views/".concat(view)], resolve);
    };
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return function () {
      return Promise.resolve("@/views/".concat(view)).then(function (s) {
        return (0, _interopRequireWildcard2.default)(require(s));
      });
    };
  }
};
exports.loadView = loadView;
var _default = permission;
exports.default = _default;