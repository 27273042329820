"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Category = Category;
exports.Department_delete = Department_delete;
exports.Department_index = Department_index;
exports.Department_save = Department_save;
exports.Department_update = Department_update;
exports.PP_save = PP_save;
exports.PP_show = PP_show;
exports.P_bep = P_bep;
exports.Picture_index = Picture_index;
exports.Picture_save = Picture_save;
exports.Sc_accountAuth = Sc_accountAuth;
exports.Sensitive_del = Sensitive_del;
exports.Sensitive_index = Sensitive_index;
exports.Sensitive_save = Sensitive_save;
exports.Server_put = Server_put;
exports.Server_save = Server_save;
exports.Spec_index = Spec_index;
exports.Spec_save = Spec_save;
exports.SupPeriod = SupPeriod;
exports.Unit = Unit;
exports.Unit_s = Unit_s;
exports.add_SupPeriod = add_SupPeriod;
exports.cdel = cdel;
exports.cdetail = cdetail;
exports.cedit = cedit;
exports.cprolist = cprolist;
exports.del_Category = del_Category;
exports.del_SupPeriod = del_SupPeriod;
exports.deployServer = deployServer;
exports.e_sc = e_sc;
exports.edit_SupPeriod = edit_SupPeriod;
exports.imp_Category = imp_Category;
exports.log = log;
exports.menu_delete = menu_delete;
exports.menu_index = menu_index;
exports.menu_save = menu_save;
exports.menu_update = menu_update;
exports.p_log = p_log;
exports.p_pbinfo = p_pbinfo;
exports.pimg_index = pimg_index;
exports.pimg_save = pimg_save;
exports.pimg_upload = pimg_upload;
exports.product_exl = product_exl;
exports.ps_del = ps_del;
exports.ps_save = ps_save;
exports.ps_search = ps_search;
exports.save_Category = save_Category;
exports.sc_channelLog = sc_channelLog;
exports.sc_checkCompany = sc_checkCompany;
exports.sc_companyList = sc_companyList;
exports.sc_countryList = sc_countryList;
exports.sc_getServer = sc_getServer;
exports.sc_getTransType = sc_getTransType;
exports.sc_getTransport = sc_getTransport;
exports.sc_getUserList = sc_getUserList;
exports.sc_getWarehouse = sc_getWarehouse;
exports.sc_index = sc_index;
exports.sc_platformList = sc_platformList;
exports.sc_save = sc_save;
exports.sc_update = sc_update;
exports.sen_del = sen_del;
exports.sen_import = sen_import;
exports.server_index = server_index;
exports.sku_create = sku_create;
exports.sku_index = sku_index;
exports.sku_rules = sku_rules;
exports.spec_del = spec_del;
exports.unit_del = unit_del;
exports.wl_del = wl_del;
exports.wl_index = wl_index;
exports.wl_save = wl_save;
var _request = _interopRequireDefault(require("@/utils/request"));
// import Cookies from 'js-cookie'

// 单位管理列表
function Unit(data) {
  return (0, _request.default)({
    url: '/Unit/index',
    method: 'get',
    params: data
  });
}
// 新增单位管理列表
function Unit_s(data) {
  return (0, _request.default)({
    url: '/Unit/save',
    method: 'post',
    data: data
  });
}
//单位管理日志列表
function log(data) {
  return (0, _request.default)({
    url: '/Log/index',
    method: 'get',
    params: data
  });
}
//账期列表
function SupPeriod(data) {
  return (0, _request.default)({
    url: '/SupPeriod/index',
    method: 'get',
    params: data
  });
}
//新增账期
function add_SupPeriod(data) {
  return (0, _request.default)({
    url: '/SupPeriod/create',
    method: 'post',
    params: data
  });
}
//删除账期
function del_SupPeriod(data) {
  return (0, _request.default)({
    url: '/SupPeriod/delete',
    method: 'post',
    data: data
  });
}
// 修改账期
function edit_SupPeriod(data) {
  return (0, _request.default)({
    url: '/SupPeriod/edit',
    method: 'post',
    data: data
  });
}
//分类管理列表
function Category(data) {
  return (0, _request.default)({
    url: '/Category/index',
    method: 'get',
    params: data
  });
}
//分类管理添加
function save_Category(data) {
  return (0, _request.default)({
    url: '/Category/save',
    method: 'post',
    data: data
  });
}
//分类管理删除
function del_Category(data) {
  return (0, _request.default)({
    url: '/Category/delete',
    method: 'post',
    data: data
  });
}
//分类管理导入
function imp_Category(data) {
  return (0, _request.default)({
    url: '/Category/import',
    method: 'post',
    data: data
  });
}
//自定义物流属性列表
function wl_index(data) {
  return (0, _request.default)({
    url: '/Logistic/index',
    method: 'get',
    params: data
  });
}
//添加自定义物流属性列表
function wl_save(data) {
  return (0, _request.default)({
    url: '/Logistic/save',
    method: 'post',
    data: data
  });
}
//删除自定义物流属性列表
function wl_del(data) {
  return (0, _request.default)({
    url: '/Logistic/bathDelete',
    method: 'post',
    data: data
  });
}
//敏感词列表
function Sensitive_index(data) {
  return (0, _request.default)({
    url: '/Sensitive/index',
    method: 'get',
    params: data
  });
}
//敏感词添加
function Sensitive_save(data) {
  return (0, _request.default)({
    url: '/Sensitive/save',
    method: 'post',
    data: data
  });
}
//删除敏感词汇
function Sensitive_del(data) {
  return (0, _request.default)({
    url: '/Sensitive/delete',
    method: 'post',
    data: data
  });
}
//规格管理列表
function Spec_index(data) {
  return (0, _request.default)({
    url: '/Spec/index',
    method: 'get',
    params: data
  });
}
//规格管理添加
function Spec_save(data) {
  return (0, _request.default)({
    url: '/Spec/save',
    method: 'post',
    data: data
  });
}
//调用产品图片
function PP_show(data) {
  return (0, _request.default)({
    url: '/ProductPicture/show',
    method: 'get',
    params: data
  });
}
// 保存调用产品图片
function PP_save(data) {
  return (0, _request.default)({
    url: '/ProductPicture/save',
    method: 'post',
    data: data
  });
}
//批量上传图片
function Picture_save(data) {
  return (0, _request.default)({
    url: '/Picture/save',
    method: 'post',
    data: data
  });
}
// 敏感词汇上传
function sen_import(data) {
  return (0, _request.default)({
    url: '/Sensitive/import',
    method: 'post',
    data: data
  });
}
// 敏感词汇批量删除
function sen_del(data) {
  return (0, _request.default)({
    url: '/Sensitive/bathDelete',
    method: 'post',
    data: data
  });
}
// 新增组合sku获取
function sku_index(data) {
  return (0, _request.default)({
    url: '/CombinationSku/index',
    method: 'get',
    params: data
  });
}
// 新增组合sku
function sku_create(data) {
  return (0, _request.default)({
    url: '/CombinationSku/create',
    method: 'post',
    data: data
  });
}
//上传产品图片列表
function pimg_index(data) {
  return (0, _request.default)({
    url: '/ProductImage/index',
    method: 'get',
    params: data
  });
}
// 上传
function pimg_upload(data) {
  return (0, _request.default)({
    url: '/ProductImage/upload',
    method: 'post',
    data: data
  });
}
// 保存
function pimg_save(data) {
  return (0, _request.default)({
    url: '/ProductImage/save',
    method: 'post',
    data: data
  });
}
// sku获取
function cprolist(data) {
  return (0, _request.default)({
    url: '/CombinationSku/proList',
    method: 'get',
    params: data
  });
}
// 组合sku详情
function cdetail(data) {
  return (0, _request.default)({
    url: '/CombinationSku/detail',
    method: 'get',
    params: data
  });
}
// sku 删除
function cdel(data) {
  return (0, _request.default)({
    url: '/CombinationSku/deleteSku',
    method: 'post',
    data: data
  });
}
// 组合sku保存
function cedit(data) {
  return (0, _request.default)({
    url: '/CombinationSku/edit',
    method: 'post',
    data: data
  });
}
// 组合变体获取
function p_pbinfo(data) {
  return (0, _request.default)({
    url: '/Product/proBasicInfo',
    method: 'get',
    params: data
  });
}
//新增自定义属性标题和选项
function ps_save(data) {
  return (0, _request.default)({
    url: '/ProductSpec/save',
    method: 'post',
    data: data
  });
}
// 删除自定义属性
function ps_del(data) {
  return (0, _request.default)({
    url: '/Spec/delete',
    method: 'post',
    data: data
  });
}
//sku查询对应的自定义规格
function ps_search(data) {
  return (0, _request.default)({
    url: '/ProductSpec/search',
    method: 'get',
    params: data
  });
}
// 图片获取回显
function Picture_index(data) {
  return (0, _request.default)({
    url: '/ProductPicture/index',
    method: 'get',
    params: data
  });
}
// 上传产品库
function P_bep(data) {
  return (0, _request.default)({
    url: '/Product/bacthExportProduct',
    method: 'post',
    data: data
  });
}
// 单位管理批量删除
function unit_del(data) {
  return (0, _request.default)({
    url: '/Unit/bathDelete',
    method: 'post',
    data: data
  });
}
// 规格管理批量删除
function spec_del(data) {
  return (0, _request.default)({
    url: '/ProductSpec/bathDelete',
    method: 'post',
    data: data
  });
}
// 敏感词效验功能
function e_sc(data) {
  return (0, _request.default)({
    url: '/Sensitive/sensitiveCheck',
    method: 'get',
    params: data
  });
}
// 产品库导入日志
function p_log(data) {
  return (0, _request.default)({
    url: '/Product/getImplodLog',
    method: 'get',
    params: data
  });
}
// 账号
// 渠道列表
function sc_index(data) {
  return (0, _request.default)({
    url: '/ShopChannel',
    method: 'get',
    params: data
  });
}
// 操作记录列表
function sc_channelLog(data) {
  return (0, _request.default)({
    url: '/ShopChannel/channelLog',
    method: 'get',
    params: data
  });
}
//新增渠道
function sc_save(data) {
  return (0, _request.default)({
    url: '/ShopChannel',
    method: 'post',
    data: data
  });
}
// 编辑渠道
function sc_update(data, id) {
  return (0, _request.default)({
    url: '/ShopChannel/' + id,
    method: 'put',
    data: data
  });
}
// sku规则测试
function sku_rules(data) {
  return (0, _request.default)({
    url: '/ChannelSkuRule/checkRule',
    method: 'get',
    params: data
  });
}
//获取平台列表接口
function sc_platformList(data) {
  return (0, _request.default)({
    url: '/SelectCondition/platformList',
    method: 'get',
    params: data
  });
}
// 获取全部国家接口
function sc_countryList(data) {
  return (0, _request.default)({
    url: '/SelectCondition/countryList',
    method: 'get',
    params: data
  });
}
//获取普通运费/FBA运费类型 运输方式接口
function sc_getTransport(data) {
  return (0, _request.default)({
    url: '/SelectCondition/getTransport',
    method: 'get',
    params: data
  });
}
//获取全部仓库接口
function sc_getWarehouse(data) {
  return (0, _request.default)({
    url: '/SelectCondition/getWarehouse',
    method: 'get',
    params: data
  });
}
//获取全部运输类型接口
function sc_getTransType(data) {
  return (0, _request.default)({
    url: '/SelectCondition/getTransType',
    method: 'get',
    params: data
  });
}
//获取所有服务器列表
function sc_getServer(data) {
  return (0, _request.default)({
    url: '/Server',
    method: 'get',
    params: data
  });
}
//部署服务器接口
function deployServer(data) {
  return (0, _request.default)({
    url: '/Server/deployServer',
    method: 'get',
    params: data
  });
}
// 所属公司列表/查询单个公司信息
function sc_companyList(data) {
  return (0, _request.default)({
    url: '/ShopChannel/companyList',
    method: 'get',
    params: data
  });
}
//新增服务器信息
function Server_save(data) {
  return (0, _request.default)({
    url: '/Server',
    method: 'post',
    data: data
  });
}
//公司校验接口
function sc_checkCompany(data) {
  return (0, _request.default)({
    url: '/ShopChannel/checkCompany',
    method: 'get',
    params: data
  });
}
//获取用户列表（根据角色获取）
function sc_getUserList(data) {
  return (0, _request.default)({
    url: '/User/getUserList',
    method: 'get',
    params: data
  });
}
// 单个服务器列表
function server_index(id) {
  return (0, _request.default)({
    url: '/Server',
    method: 'get',
    params: id
  });
}
//编辑服务器信息
function Server_put(data, id) {
  return (0, _request.default)({
    url: '/Server/' + id,
    method: 'put',
    data: data
  });
}
//亚马逊授权
function Sc_accountAuth(data) {
  return (0, _request.default)({
    url: '/ShopChannel/accountAuth',
    method: 'get',
    params: data
  });
}
// 产品库指定自定义导出
function product_exl(data) {
  return (0, _request.default)({
    url: '/Product/exportProduct',
    method: 'get',
    params: data
  });
}

// 权限 -->
// 部门列表
function Department_index(data) {
  return (0, _request.default)({
    url: '/Department',
    method: 'get',
    params: data
  });
}
// 新增部门
function Department_save(data) {
  return (0, _request.default)({
    url: '/Department',
    method: 'post',
    data: data
  });
}
// 编辑部门
function Department_update(id, data) {
  return (0, _request.default)({
    url: '/Department/' + id,
    method: 'put',
    data: data
  });
}
//删除部门
function Department_delete(id, data) {
  return (0, _request.default)({
    url: '/Department/' + id,
    method: 'delete',
    data: data
  });
}
// 菜单列表
function menu_index(data) {
  return (0, _request.default)({
    url: '/Menu',
    method: 'get',
    params: data
  });
}
// 新增菜单
function menu_save(data) {
  return (0, _request.default)({
    url: '/Menu',
    method: 'post',
    data: data
  });
}
// 编辑菜单
function menu_update(id, data) {
  return (0, _request.default)({
    url: '/Menu/' + id,
    method: 'put',
    data: data
  });
}
// 删除菜单
function menu_delete(id, data) {
  return (0, _request.default)({
    url: '/Menu/' + id,
    method: 'delete',
    data: data
  });
}