"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  nickname: 'test1',
  tip1: '是否确认删除参数编号为',
  tip2: '的数据项',
  tip3: '警告',
  ok: '确定',
  cancel: '取消',
  del: '删除成功'
};
var _default = languagePack;
exports.default = _default;