"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  name: '产品备注',
  label1: '产品状态:',
  label2: '是否预售:',
  checkbox2_1: '预售产品',
  title2: '到货日期:',
  placeholder2: '请选择到货日期',
  label3: '出售起止时间:',
  range3: '至',
  start: '出售起始时间',
  end: '出售结束时间',
  label4: '开发备注:',
  label5: '采购收货备注:',
  placeholder5: '会显示在采购单产品详情外，方便收货人员查看注意事项',
  label6: '产品打包状态:',
  label7: '编辑备注:',
  placeholder7: '这是一个很厉害的富文本编辑器',
  title7: '公网文件路径：',
  button7: '上传文件插入编辑器',
  label8: '图片处理备注:',
  label9: '质检备注:',
  button9: '删除质检照片',
  save: '保存并继续编辑',
  clo: '关闭窗口',
  data_t1: '黑色',
  data_t2: '红色',
  data_t3: '不需打包',
  data_t4: '需自行打包',
  data_t5: '需供应商打包',
  label10: '发货打包备注:'
};
var _default = languagePack;
exports.default = _default;