"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  label1: "下载",
  txt1: "下载标准格式表格（更新日期 2021-04-27）",
  buttonupdate: "下载表格",
  txt2: "（如果点击后没有反应，请检查浏览器是否屏蔽了弹窗功能）",
  label2: "上传",
  txt3: "请确认无误后上传",
  buttonup: "上传文件",
  txt4: "不要随意修改Excel模板格式，否则可能导致批量操作失败",
  txt5: "请完全依照下载模板的格式填入信息，不要使用函数、算法等操作Excel",
  txt6: "否则可能导致读取的数值和实际不符",
  dialogtitle: "分类批量导入",
  msgerror: "上传文件只能是 excel/xls 格式!",
  tableitem1: "操作日志",
  tableitem2: "操作时间",
  tableitem3: "操作人",
  tableitem4: "上传结果",
  buttonsele: "查看",
  buttontitle: "确定"
};
var _default = languagePack;
exports.default = _default;