"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
var _default = [{
  path: '/mobile',
  component: _layout.default,
  redirect: '/mobile_views/quick_views/bussiness_clue_add',
  meta: {
    title: '移动端',
    icon: 'home 375',
    affix: true
  },
  children: [{
    path: '/mobile_views/quick_views/bussiness_clue_add',
    component: function component(resolve) {
      return require(['@/mobile_views/quick_views/bussiness_clue_add'], resolve);
    },
    name: 'bussiness_clue_add',
    meta: {
      title: '快速添加线索',
      icon: 'home 375',
      affix: true
    }
  }, {
    path: '/mobile_views/quick_views/bussiness_clue_receive',
    component: function component(resolve) {
      return require(['@/mobile_views/quick_views/bussiness_clue_receive'], resolve);
    },
    name: 'bussiness_clue_receive',
    meta: {
      title: '快速添加线索',
      icon: 'home 375',
      affix: true
    }
  }]
}];
exports.default = _default;