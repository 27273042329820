"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchPurchasePrint = batchPurchasePrint;
exports.getAppList = getAppList;
exports.getAppListOld = getAppListOld;
exports.getClientPanel = getClientPanel;
exports.getOpportunityCount = getOpportunityCount;
exports.getPurchaseListBySupplier = getPurchaseListBySupplier;
exports.getRouters = void 0;
exports.getSaleCount = getSaleCount;
exports.getoOperationPanel = getoOperationPanel;
exports.receiveStatistics = receiveStatistics;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取路由
var getRouters = function getRouters() {
  return (0, _request.default)({
    url: '/Menu/getRoutes',
    method: 'get'
  });
};

// 获取路由
// 线上销售->销售列表
exports.getRouters = getRouters;
function batchPurchasePrint(params) {
  return (0, _request.default)({
    url: 'BatchPrint/batchPurchasePrint',
    method: 'get',
    params: params
  });
}

// 获取店掌宝点餐收银的列表
function getAppList(params) {
  return (0, _request.default)({
    url: 'https://dcserver.dianzhangbao.com.cn/peopleList',
    method: 'get',
    params: params
  });
}
function getAppListOld(params) {
  return (0, _request.default)({
    url: 'https://dcserver.dianzhangbao.net/peopleList',
    method: 'get',
    params: params
  });
}

// 线索领取统计
function receiveStatistics(params) {
  return (0, _request.default)({
    url: '/Opportunity/statistics',
    method: 'get',
    params: params
  });
}

// 线索领取统计
function getSaleCount(params) {
  return (0, _request.default)({
    url: '/SalesOrder/statistics',
    method: 'get',
    params: params
  });
}

// 运营统计
function getOpportunityCount(params) {
  return (0, _request.default)({
    url: '/Opportunity/getOpportunityStatistics',
    method: 'get',
    params: params
  });
}

// 采购看板
function getPurchaseListBySupplier(params) {
  return (0, _request.default)({
    url: '/listBySupplier',
    method: 'get',
    params: params
  });
}

// 运营看板
function getoOperationPanel(params) {
  return (0, _request.default)({
    url: '/operationPanel',
    method: 'get',
    params: params
  });
}

// 客户看板
function getClientPanel(params) {
  return (0, _request.default)({
    url: '/customerPanel',
    method: 'get',
    params: params
  });
}