"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _languagePack;
var languagePack = (_languagePack = {
  name: '产品价格',
  label1: '产品状态:',
  label2: '产品状态备注:',
  label3: '默认产品供应价:',
  label4: '供应商报价:',
  label5: '采购运费:',
  label6: '采购链接:',
  button6: '跳转链接',
  label7: '供应商名称:',
  placeholder7: '带搜索功能',
  button7_1: '新增供应商',
  button7_2: '编辑供应商',
  label8: '供应商付款方式:',
  placeholder8: '网络支付',
  label9: '供应商SKU:',
  label10: '供应商发货地:',
  placeholder10_1: '省',
  placeholder10_2: '市',
  label11: '批发价格:',
  title11: '价格',
  button11: '新增批发价',
  label12: '是否提供备品:',
  yes: '是',
  label13: '采购天数:',
  title13: '(采购天数=生产天数+采购运输天数+入库天数)',
  label14: '生产天数:',
  label15: '采购运输天数:',
  title15: '(一般本地一天，珠三角2天，珠三角以外4天)',
  label16: '入库天数:',
  label17: '库存数:',
  label18: '备注:',
  ok: '确定',
  add_gh: '重置',
  label19: '更新价格区间:',
  label20: '开票税点:',
  label21: '含税价:',
  label22: '产品销售价(原价):',
  label23: '产品销售价(现价):',
  title23_1: '起批量：',
  title23_2: '单位起卖',
  label24: '产品销售价(限价~多区间):',
  title24_1: '价格：',
  title24_2: '售价：',
  title24_3: '运费',
  title24_4: '数量:',
  save: '保存并继续编辑',
  clo: '关闭窗口',
  label25: '基本信息',
  label26: '财务资料',
  label27: '联系信息',
  label28: '操作人员信息',
  label29: '文件资料存档',
  t_title1: '是否默认',
  t_title2: '供应商名称',
  t_title3: '供应商SKU',
  t_title4: '供应商报价',
  t_title5: '采购运费',
  t_title6: '批发数量',
  t_title7: '批发价',
  t_title8: '备注',
  t_title9: '采购天数',
  t_title10: '报价时间',
  t_title11: '开发人员',
  t_title12: '操作',
  rules1: '请选择供应商名称',
  rules2: '请输入供应商报价',
  rules3: '请选择供应商发货地',
  rules4: '请输入采购天数',
  rules5: '采购运费不能为空',
  m_title: '供应商信息添加',
  data1: '在售',
  data2: '滞销',
  data3: '停产',
  data4: '锁定',
  data5: '暂时缺货',
  data6: '清库',
  data7: '根据供货价',
  data8: '根据售价',
  data9: '网络在线付款',
  data10: '现金付款',
  data11: '转账付款',
  data12: '阿里账期付款',
  title25: '确定删除选中产品?',
  title26: '提示'
}, (0, _defineProperty2.default)(_languagePack, "ok", '确定'), (0, _defineProperty2.default)(_languagePack, "cancel", '取消'), (0, _defineProperty2.default)(_languagePack, "title27", '删除成功!'), (0, _defineProperty2.default)(_languagePack, "title28", '删除失败!'), (0, _defineProperty2.default)(_languagePack, "data13", '下架'), (0, _defineProperty2.default)(_languagePack, "title29", "已取消"), (0, _defineProperty2.default)(_languagePack, "label30", "数量区间不对"), (0, _defineProperty2.default)(_languagePack, "label31", "开始的数量不能比上次结束的数量低"), _languagePack);
var _default = languagePack;
exports.default = _default;