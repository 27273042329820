"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  editor: '产品编辑',
  notes1: '注：产品英文名标题，用于Amazon/Ebay/Wish/速卖通等平台Listing上架标题；平台暂不支持中文输入',
  label1: '产品英文名:',
  label2: 'SEO标题:',
  button1: '复用产品英文名',
  label3: 'SEO关键字:',
  button3: '提取平台关键词',
  placeholder3: '若有多个关键词“,”分开',
  label4: '搜索关键字:',
  placeholder4: '同SEO关键词；若有多个关键词以“,”分开;暂不支持特殊字符上架',
  button4: '复用SEO关键词',
  label5: 'SEO描述:',
  label6: '产品详细描述:',
  placeholder6: '',
  notes6: '注：平台暂不支持特殊字符上架',
  label7: '产品特征亮点:',
  notes7: '注：若有多个亮点，每个亮点占一行；回车输入可换行；平台暂不支持特殊字符上架',
  label8: '包装清单:',
  label9: '编辑状态:',
  checkbox1: '已编辑',
  save: '保存并继续编辑',
  clo: '关闭窗口',
  rules1: '请输入产品报关英文名',
  message: '暂不支持特殊字符',
  option1: "黑色",
  option2: "红色"
};
var _default = languagePack;
exports.default = _default;