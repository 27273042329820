"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
var _default = {
  name: "batEditor",
  props: {},
  data: function data() {
    return {};
  },
  methods: {}
};
exports.default = _default;