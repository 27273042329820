"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  name: '侵权记录查询',
  index: '序号',
  platform: '投诉平台',
  type: '侵权类型',
  brand: '品牌',
  result: '验证结果',
  details: '详情查询',
  save: '保存并继续编辑',
  clo: '关闭窗口'
};
var _default = languagePack;
exports.default = _default;