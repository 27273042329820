"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  txt1: "基本信息",
  div1: "系统自动生成",
  label1: "产品SPU:",
  label2: "产品SKU:",
  tablelabel1: "产品sku",
  label3: "产品中文名:",
  label4: "产品类目:",
  label5: "选择类型",
  label6: "默认本地发货仓库:",
  placeholder1: "请选择本地发货仓库",
  label7: "采购人员:",
  placeholder2: "请选择采购人员",
  label8: "开发人员:",
  placeholder3: "请选择开发人员",
  label9: "添加人员:",
  placeholder4: "请选择负责人员",
  label10: "指定审核人:",
  placeholder5: "请选择审核人员",
  label11: "推荐指数:",
  placeholder6: "星越多,重要程度越高",
  label12: "产品来源:",
  checkbox: "需要拍照",
  label13: "开发来源:",
  label14: "图片来源:",
  label15: "精准关键词:",
  label16: "产品材质:",
  placeholder7: "请输入内容",
  label17: "是否支持海外仓直采:",
  checkbox1: "是",
  label18: "产品侵权风险:",
  placeholder8: "请选择侵权风险",
  label19: "产品类型:",
  placeholder9: "请选择产品类型",
  label20: "产品物流属性:",
  placeholder10: "请选择物流属性",
  label21: "物流查验类型：",
  placeholder11: "请选择物流查验类型",
  label22: "强电插头:",
  txt2: "（不同国家插头规格可能不一样，设置后，发货配货时会对应显示订单国插头规格）",
  label23: "参考网站链接:",
  buttontitle: "跳转链接",
  label24: "单个产品重量:",
  txt3: "毛重：",
  txt4: "净重：",
  txt5: "包裹重：",
  txt6: "长：",
  txt7: "宽：",
  txt8: "高：",
  txt9: "体积：",
  txt10: "周长：",
  txt11: "每箱体积：",
  label25: "单个包装尺寸:",
  label26: "外箱包装尺寸:",
  label27: "每箱PCS数量:",
  label28: "每箱产品重量:",
  txt12: "毛重：",
  txt13: "净重：",
  label29: "产品单位:",
  label30: "规格型号:",
  label31: "新增主属性:",
  placeholder12: "请输入主属性名称",
  txt14: "添加主属性",
  label32: "颜色:",
  label33: "尺码:",
  checkbox2: "鞋子尺码",
  placeholder13: "请输入尺寸",
  placeholder14: "请输入内容",
  placeholder15: "请输入子属性名称",
  txt15: "添加子属性",
  txt16: "删 除",
  placeholder16: "请输入内容",
  buttonsave: "保存并继续编辑",
  buttonclone: "关闭窗口",
  tabs1: "类别选择",
  tabs2: "类别搜索",
  txt17: "你选择的是：",
  buttontype: "确定选择类别",
  txt18: "类别关键词：",
  placeholder17: "请输入类别关键词",
  msgerror1: "最多只能选择3个主属性",
  msgerror2: "必须选择至少1个主属性",
  msgerror3: "请选择要输入的尺寸",
  msgerror4: "请补充完整需添加的属性",
  msgerror5: "信息填写错误",
  dialogtitle: "选择单个类别",
  confirmneirong: "选择成功",
  confirmtitle: "提示",
  txt19: "暂无选择",
  msgerror6: "请输入需要添加的主属性名称",
  msgerror7: "请填写需要添加的子属性",
  label34: "美工:",
  placeholder18: "请选择美工",
  msgerror8: "这是必填项",
  option1: "系统采集",
  option2: "开发采集",
  option3: "新样品",
  option4: "供应商后台",
  option5: "自主开发",
  option6: "供应商后台",
  option7: "指定开发",
  option8: "采集图",
  option9: "供应商供图",
  option10: "自己拍摄图",
  option11: "非仿牌",
  option12: "仿牌",
  option13: "灰色产品",
  option14: "普通产品",
  option15: "采购包材",
  option16: "物流包材",
  option17: "配件",
  option18: "否",
  option19: "是_高",
  option20: "是_中",
  option21: "是_低",
  option22: "是",
  option23: "否",
  option24: "无",
  option25: "有",
  yanzhen1: "请输入产品中文名",
  yanzhen2: "请选择产品物流属性",
  yanzhen3: "请输入产品材质"
};
var _default = languagePack;
exports.default = _default;