"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  name: '产品规格参数',
  label1: '新增主属性:',
  placeholder1: '请输入主属性名称',
  button: '添加主属性',
  label2: '产品SKU:',
  placeholder2: '请输入参数名称',
  label3: '颜色:',
  title3_1: '找不到想要的颜色？点击',
  button3: '编辑颜色',
  title3_2: '去添加新的颜色。',
  label4: '尺码:',
  checkbox4: '鞋子尺码',
  s_size: '码 鞋长:',
  title4_1: '找不到想要的尺码？点击',
  button4: '编辑尺码',
  title4_2: '去添加新的尺码。',
  placeholder_c: '请输入子属性名称',
  add_child: '添加子属性',
  edit_child: '编辑子属性',
  over: '编辑完成',
  del_child: '删除',
  save: '保存并继续编辑',
  clo: '关闭窗口'
};
var _default = languagePack;
exports.default = _default;