"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  selectplaceholder: "请选择",
  txt1: "请严格按照格式排列，不要打乱顺序，一行一个（规范操作：从Excel中复制）；",
  txt2: "若子SKU已有包裹重量，则Excel中组和SKU不需要录入单个产品的包裹首重",
  span1: "格式:",
  span2: "主SKU &nbsp;&nbsp;&nbsp;&nbsp;子SKU&nbsp;&nbsp;&nbsp;&nbsp; 子SKU数量",
  placeholder1: "请输入内容",
  buttontitle: "开始生成",
  span3: "生成结果:",
  options1: "选择sku类型",
  options2: "产品sku",
  options3: "自定义sku"
};
var _default = languagePack;
exports.default = _default;