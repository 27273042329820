"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  buttonwuliu: "添加物流属性",
  buttonlog: "日志",
  inputplaceholder1: "关键字",
  buttonshaixuan: "查询",
  buttonchongzhi: "重置",
  selectbutch: "批量设置",
  buttonsubimt: "提交",
  tablenamecn: "中文名称",
  tableshuxin: "属性值",
  tablexitong: "是否系统",
  tablejihuo: "是否激活",
  tablepaixu: "排序",
  tablebeizhu: "备注",
  tablegenxin: "更新人",
  tableupdatetime: "更新时间",
  formnamecn: "中文名称",
  formshuxin: "属性",
  formpaixu: "排序",
  formjihuo: "是否激活",
  radio1: "激活",
  radio2: "未激活",
  formbeizhu: "备注",
  inputplaceholder2: "请输入内容",
  buttonqueding: "确 定",
  buttonquxiao: "取 消",
  tablelog: "操作日志",
  tablecaozuo: "操作人",
  tablecreatetime: "操作时间",
  yanzhen1: "中文名称不能为空",
  yanzhen2: "属性不能为空",
  yanzhen3: "排序不能为空",
  yanzhen4: "是否激活不能为空",
  yanzhen5: "备注不能为空",
  guolvqi1: "是",
  guolvqi2: "否",
  buttonadd: "添加参数",
  confirmdel: "此操作将永久删除该文件, 是否继续?",
  confirmtitle: "提示",
  confirmquxiao: "已取消删除",
  msgtishi: "请选择要操作的数据",
  dialogtitle1: "添加自定义物流属性",
  dialogtitle2: "操作日志"
};
var _default = languagePack;
exports.default = _default;