"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default = {
  name: "SButton",
  props: {
    icon: {
      type: String,
      default: "fa-plus-square"
    },
    title: {
      type: String,
      default: "未命名"
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$emit("handleClick");
    }
  }
};
exports.default = _default;