var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      attrs: {
        prop: _vm.customProp,
        label: _vm.customTitle ? _vm.customTitle : "",
      },
    },
    [
      _vm._l(_vm.dynamicTags, function (tag) {
        return _c(
          "el-tag",
          {
            key: tag,
            attrs: { closable: "", "disable-transitions": false },
            on: {
              close: function ($event) {
                return _vm.handleClose(tag)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(tag) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _c("el-autocomplete", {
        staticClass: "input-new-tag",
        attrs: {
          size: "small",
          "fetch-suggestions": _vm.querySearch,
          placeholder: "请输入内容",
          "trigger-on-focus": false,
        },
        on: { select: _vm.handleSelect, blur: _vm.handleInputConfirm },
        nativeOn: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.handleInputConfirm($event)
          },
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }