"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  buttonadd: "添加",
  ptype: "类型",
  tableid: "序号",
  tabletitle: "禁售平台",
  tableguojia: "禁售国家",
  tablecaozuo: "操作",
  tablebeizhu: "备注",
  buttonsave: "保存",
  confirmneirong: "您确定要还原数据吗?",
  confirmdel: "确认删除当前选中值吗？",
  confirmdeltitle: "提示",
  confirmtype: "类型不能为空",
  confirmbutton: "确定",
  cancelButton: "取消",
  label1: "累计",
  label2: "替换"
};
var _default = languagePack;
exports.default = _default;