"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  watch: {
    $route: {
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
      handler: function handler(val) {
        setTimeout(function () {
          // 解决element - ui 远程搜索el - select在ios端上无法输入的问题
          Array.from(document.getElementsByClassName('el-select')).forEach(function (item) {
            item.children[0].children[0].removeAttribute('readOnly');
            item.children[0].children[0].onblur = function () {
              var _this = this;
              console.log('%c 🥚 _this: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', _this);
              setTimeout(function () {
                _this.removeAttribute('readOnly');
              }, 200);
            };
          });
        }, 1000);
      }
    }
  }
};
exports.default = _default;