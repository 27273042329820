"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title: "批量上传产品SPU图片",
  choice: '请选择操作类型:',
  add: "新增",
  cover: "覆盖",
  one: '1',
  upload: '上传',
  correct: '请确认无误后上传',
  ysb: '上传压缩包:',
  condition1: '请按产品spu命名压缩包内每一个文件夹名称',
  condition2: '压缩包大小限制200MB以内，并且单张图片不能大于10M',
  condition3: '上传之前请正确选择',
  h: '或',
  requirement1: '上传文件只能是 rar、zip、7z格式!',
  requirement2: '上传文件大小不能超过 10MB!',
  ysb1: '上传压缩包'
};
var _default = languagePack;
exports.default = _default;