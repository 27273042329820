var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    { staticClass: "mg-b-10", on: { click: _vm.handleClick } },
    [
      _c("i", {
        staticClass: "fa fa-lg",
        class: _vm.icon,
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(_vm._s(_vm.title)),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }