"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  top: '翻译',
  lage1: '英语',
  lage2: '法语',
  lage3: '德语',
  lage4: '印第语',
  lage5: '西班牙语',
  lage6: '意大利语',
  lage7: '俄语',
  lage8: '印尼语',
  lage9: '泰语',
  lage10: '波兰语',
  lage11: '荷兰语',
  lage12: '葡萄牙语',
  lage13: '阿拉伯语',
  lage14: '瑞典语',
  lage15: '土耳其语',
  lage16: '韩语',
  span1: '字段名称',
  span2: '原文/译文',
  label1: '产品名称:',
  label2: 'SEO标题:',
  label3: 'SEO关键字:',
  label4: '搜索关键字:',
  span4: '注：多个请用英文逗号分隔',
  label5: 'SEO描述:',
  span5: '注：前往其他页面请先保存',
  label6: '产品特征亮点：',
  span6: '注：多个请用“|”分隔',
  label7: '包装清单:',
  button1: '翻译',
  button2: '保存并继续编辑',
  button3: '关闭窗口',
  tip1: '请选择需要翻译的语言后再进行翻译',
  tip2: '提示'
};
var _default = languagePack;
exports.default = _default;