"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  txt1: "产品发货包材",
  tablelabel1: "类型",
  tablelabel2: "型号",
  tablelabel3: "体积",
  tablelabel4: "价格",
  buttonsave: "保存并继续编辑",
  buttonclone: "关闭窗口",
  dialogtitle: "选择包材",
  tabletitle: "类型",
  tabletitle1: "型号",
  tabletitle2: "体积",
  tabletitle3: "价格",
  tabletitle4: "备注",
  buttonbatchPackage: "保存",
  guolv1: "纸箱",
  guolv2: "信封箱",
  guolv3: "气泡信封袋",
  guolv4: "PE快递袋",
  guolv5: "自定义",
  msgsuccess: "设置成功！",
  msgwarning: "设置失败！",
  buttontitle: "添加"
};
var _default = languagePack;
exports.default = _default;