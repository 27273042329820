"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaleOnlineList = SaleOnlineList;
exports.StockAllotDelete = StockAllotDelete;
exports.StockAllotIndex = StockAllotIndex;
exports.StockAllotSave = StockAllotSave;
exports.StockAllotShow = StockAllotShow;
exports.StockBrandRead = StockBrandRead;
exports.StockCategoryBashDelete = StockCategoryBashDelete;
exports.StockCategoryDelete = StockCategoryDelete;
exports.StockCategoryIndex = StockCategoryIndex;
exports.StockCategorySave = StockCategorySave;
exports.StockCirculationConfirm = StockCirculationConfirm;
exports.StockCirculationDelete = StockCirculationDelete;
exports.StockCirculationDeletes = StockCirculationDeletes;
exports.StockCirculationIndex = StockCirculationIndex;
exports.StockCirculationSave = StockCirculationSave;
exports.StockCirculationShow = StockCirculationShow;
exports.StockCodeIndex = StockCodeIndex;
exports.StockCommonQuantity = StockCommonQuantity;
exports.StockCommonRegion = StockCommonRegion;
exports.StockCommonShelves = StockCommonShelves;
exports.StockInDetailIndex = StockInDetailIndex;
exports.StockInDetailShow = StockInDetailShow;
exports.StockInSave = StockInSave;
exports.StockInventoryConfirm = StockInventoryConfirm;
exports.StockInventoryDelete = StockInventoryDelete;
exports.StockInventoryIndex = StockInventoryIndex;
exports.StockInventoryPartList = StockInventoryPartList;
exports.StockInventorySave = StockInventorySave;
exports.StockInventoryShow = StockInventoryShow;
exports.StockOtherSave = StockOtherSave;
exports.StockOtherShow = StockOtherShow;
exports.StockOutDetailIndex = StockOutDetailIndex;
exports.StockOutDetailShow = StockOutDetailShow;
exports.StockOutIndex = StockOutIndex;
exports.StockOutSave = StockOutSave;
exports.StockOutShow = StockOutShow;
exports.StockPartCommon = StockPartCommon;
exports.StockPartCountNum = StockPartCountNum;
exports.StockPartList = StockPartList;
exports.StockPartListSearch = StockPartListSearch;
exports.StockPartRead = StockPartRead;
exports.StockPartShow = StockPartShow;
exports.StockPriceUpBatchDelete = StockPriceUpBatchDelete;
exports.StockPriceUpBatchSave = StockPriceUpBatchSave;
exports.StockPriceUpDelete = StockPriceUpDelete;
exports.StockPriceUpIndex = StockPriceUpIndex;
exports.StockPriceUpRead = StockPriceUpRead;
exports.StockRegionRead = StockRegionRead;
exports.StockShelvesRead = StockShelvesRead;
exports.StockToolList = StockToolList;
exports.StockToolRead = StockToolRead;
exports.StockToolsOutIndex = StockToolsOutIndex;
exports.StockToolsOutSave = StockToolsOutSave;
exports.StockToolsOutShow = StockToolsOutShow;
exports.StockUnitRead = StockUnitRead;
exports.StockWarehouseRead = StockWarehouseRead;
exports.adminIndex = adminIndex;
exports.batchPurchasePrint = batchPurchasePrint;
exports.chargeBackAdd = chargeBackAdd;
exports.chargeBackDel = chargeBackDel;
exports.chargeBackInfo = chargeBackInfo;
exports.chargeBackList = chargeBackList;
exports.chargeBackSubmit = chargeBackSubmit;
exports.chargeBackWithdraw = chargeBackWithdraw;
exports.getLocations = getLocations;
exports.getMULPurchaseNo = getMULPurchaseNo;
exports.getOrderInfo = getOrderInfo;
exports.getPartRead = getPartRead;
exports.getPurchaseNo = getPurchaseNo;
exports.getStaffNames = getStaffNames;
exports.getSupplierCode = getSupplierCode;
exports.getSupplierName = getSupplierName;
exports.getpartList = getpartList;
exports.gettoolList = gettoolList;
exports.purchaseMULOrderInfo = purchaseMULOrderInfo;
exports.purchaseOrderAdd = purchaseOrderAdd;
exports.purchaseOrderDel = purchaseOrderDel;
exports.purchaseOrderList = purchaseOrderList;
exports.purchaseOrderSubmit = purchaseOrderSubmit;
exports.purchaseOrderWithdraw = purchaseOrderWithdraw;
exports.stockBrandIndex = stockBrandIndex;
exports.stockInIndex = stockInIndex;
exports.stockInShow = stockInShow;
exports.stockModelBashDelete = stockModelBashDelete;
exports.stockModelDelete = stockModelDelete;
exports.stockModelIndex = stockModelIndex;
exports.stockModelSave = stockModelSave;
exports.stockOtherDelete = stockOtherDelete;
exports.stockOtherIndex = stockOtherIndex;
exports.stockWarningIndex = stockWarningIndex;
exports.supplierAdd = supplierAdd;
exports.supplierBatchDel = supplierBatchDel;
exports.supplierInfo = supplierInfo;
exports.supplierList = supplierList;
exports.supplierSetActive = supplierSetActive;
exports.toooloutDelete = toooloutDelete;
exports.toooloutbatchDelete = toooloutbatchDelete;
exports.withdrawGetOrderNo = withdrawGetOrderNo;
var _request = _interopRequireDefault(require("@/utils/request"));
//选择配件列表
function getpartList(query) {
  return (0, _request.default)({
    url: '/StockPart/index',
    method: 'get',
    params: query
  });
}

//选择工具列表
function gettoolList(query) {
  return (0, _request.default)({
    url: '/StockTool/index',
    method: 'get',
    params: query
  });
}
//////////////////////////////////////////////////////////////////////
// 获取供应商列表
function supplierList(params) {
  return (0, _request.default)({
    url: '/Supplier/supplierList',
    method: 'get',
    params: params
  });
}

// 获取供应商编码
function getSupplierCode(params) {
  return (0, _request.default)({
    url: '/Supplier/getSupplierCode',
    method: 'get',
    params: params
  });
}

// 供应商详情
function supplierInfo(params) {
  return (0, _request.default)({
    url: '/Supplier/supplierInfo',
    method: 'get',
    params: params
  });
}

// 供应商添加/编辑
function supplierAdd(data) {
  return (0, _request.default)({
    url: '/Supplier/supplierAdd',
    method: 'post',
    data: data
  });
}

// 供应商批量删除
function supplierBatchDel(params) {
  return (0, _request.default)({
    url: '/Supplier/supplierBatchDel',
    method: 'get',
    params: params
  });
}

// 供应商管理->批量禁用和激活
function supplierSetActive(params) {
  return (0, _request.default)({
    url: '/Supplier/setActive',
    method: 'get',
    params: params
  });
}
// 采购订单->订单列表
function purchaseOrderList(query) {
  return (0, _request.default)({
    url: '/Purchase/orderList',
    method: 'get',
    params: query
  });
}

// // 采购订单->采购订单详情
// export function batchPurchasePrint(params) {
//   return request({
//     url: '/BatchPrint/batchPurchasePrint',
//     method: 'get',
//     params
//   })
// }

// 采购订单->采购订单打印
function purchaseMULOrderInfo(params) {
  return (0, _request.default)({
    url: '/Purchase/batchPrint',
    method: 'get',
    params: params
  });
}

// 采购订单->订单编辑/添加
function purchaseOrderAdd(data) {
  return (0, _request.default)({
    url: '/Purchase/orderAdd',
    method: 'post',
    data: data
  });
}

// 新增采购订单->自动搜索供应商
function getOrderInfo(params) {
  return (0, _request.default)({
    url: '/Purchase/orderInfo',
    method: 'get',
    params: params
  });
}

// 新增采购订单->自动搜索供应商
function getSupplierName(params) {
  return (0, _request.default)({
    url: '/Purchase/getSupplierName',
    method: 'get',
    params: params
  });
}

// 采购订单->订单删除
function purchaseOrderDel(params) {
  return (0, _request.default)({
    url: '/Purchase/orderDel',
    method: 'get',
    params: params
  });
}

// 采购订单->提交操作
function purchaseOrderSubmit(params) {
  return (0, _request.default)({
    url: '/Purchase/orderSubmit',
    method: 'get',
    params: params
  });
}

// 采购列表->单据撤回
function purchaseOrderWithdraw(params) {
  return (0, _request.default)({
    url: '/Purchase/orderWithdraw',
    method: 'get',
    params: params
  });
}

// 采购单/采退单--获取采购采退单号
function getPurchaseNo(params) {
  return (0, _request.default)({
    url: '/Purchase/getPurchaseNo',
    method: 'get',
    params: params
  });
}

//批量打印batchPrint
function getMULPurchaseNo(params) {
  return (0, _request.default)({
    url: '/Purchase/batchPrint',
    method: 'get',
    params: params
  });
}

// 采购退单->采购退单列表
function chargeBackList(query) {
  return (0, _request.default)({
    url: '/Purchase/chargeBackList',
    method: 'get',
    params: query
  });
}

// 采购退单->删除操作
function chargeBackDel(params) {
  return (0, _request.default)({
    url: '/Purchase/chargeBackDel',
    method: 'get',
    params: params
  });
}

// 采购退单->提交操作
function chargeBackSubmit(params) {
  return (0, _request.default)({
    url: '/Purchase/chargeBackSubmit',
    method: 'get',
    params: params
  });
}

// 采购退单->打印操作
function batchPurchasePrint(params) {
  return (0, _request.default)({
    url: '/BatchPrint/batchPurchaseBackPrint',
    method: 'get',
    params: params
  });
}

// 采购退单->采购退单添加
function chargeBackAdd(data) {
  return (0, _request.default)({
    url: '/Purchase/chargeBackAdd',
    method: 'post',
    data: data
  });
}
// 采购退单->撤回操作
function chargeBackWithdraw(params) {
  return (0, _request.default)({
    url: '/Purchase/chargeBackWithdraw',
    method: 'get',
    params: params
  });
}

// 采退编辑->搜索采购单号
function withdrawGetOrderNo(params) {
  return (0, _request.default)({
    url: '/Purchase/getOrderNo',
    method: 'get',
    params: params
  });
}

// 采购退单->采购退单详情
function chargeBackInfo(params) {
  return (0, _request.default)({
    url: '/Purchase/chargeBackInfo',
    method: 'get',
    params: params
  });
}

////////////////////////////////////////////////////////////////////////////
// 仓库信息管理->车型列表
function stockModelIndex(params) {
  return (0, _request.default)({
    url: '/StockModel/index',
    method: 'get',
    params: params
  });
}

// 仓库信息管理->车型保存
function stockModelSave(data) {
  return (0, _request.default)({
    url: '/StockModel/save',
    method: 'post',
    data: data
  });
}

// 仓库信息管理->车型删除
function stockModelDelete(data) {
  return (0, _request.default)({
    url: '/StockModel/delete',
    method: 'post',
    data: data
  });
}

// 仓库信息管理-车型批量删除
function stockModelBashDelete(data) {
  return (0, _request.default)({
    url: '/StockModel/bashDelete',
    method: 'post',
    data: data
  });
}

// 仓库管理->其他出入库列表
function stockOtherIndex(params) {
  return (0, _request.default)({
    url: '/StockOther/index',
    method: 'get',
    params: params
  });
}

// 通用下拉->用户
function adminIndex(params) {
  return (0, _request.default)({
    url: '/Admin/index',
    method: 'get',
    params: params
  });
}

// 仓库管理->其他出入库删除
function stockOtherDelete(data) {
  return (0, _request.default)({
    url: '/StockOther/delete',
    method: 'post',
    data: data
  });
}

// 库存管理->其他出入库详情
function StockOtherShow(params) {
  return (0, _request.default)({
    url: '/StockOther/show',
    method: 'get',
    params: params
  });
}

// 仓库信息管理->品牌列表
function stockBrandIndex(params) {
  return (0, _request.default)({
    url: '/StockBrand/index',
    method: 'get',
    params: params
  });
}

// 库存->库存预警
function stockWarningIndex(params) {
  return (0, _request.default)({
    url: '/StockWarning/index',
    method: 'get',
    params: params
  });
}

// 出入库->入库管理列表
function stockInIndex(params) {
  return (0, _request.default)({
    url: '/StockIn/index',
    method: 'get',
    params: params
  });
}

// 出入库->入库管理查看
function stockInShow(params) {
  return (0, _request.default)({
    url: '/StockIn/show',
    method: 'get',
    params: params
  });
}

// 仓库管理->配件货物维护列表
function StockCirculationIndex(params) {
  return (0, _request.default)({
    url: '/StockCirculation/index',
    method: 'get',
    params: params
  });
}

// 仓库管理->扭转单（配件货物维护）详情
function StockCirculationShow(params) {
  return (0, _request.default)({
    url: '/StockCirculation/show',
    method: 'get',
    params: params
  });
}

// 仓库管理->扭转单（配件货物维护）保存
function StockCirculationSave(data) {
  return (0, _request.default)({
    url: '/StockCirculation/save',
    method: 'post',
    data: data
  });
}

// 仓库管理->扭转单（配件货物维护）删除
function StockCirculationDelete(data) {
  return (0, _request.default)({
    url: '/StockCirculation/delete',
    method: 'post',
    data: data
  });
}

// 仓库管理->扭转单（配件货物维护）批量删除
function StockCirculationDeletes(data) {
  return (0, _request.default)({
    url: '/StockCirculation/bashDelete',
    method: 'post',
    data: data
  });
}

// 仓库管理->调拨单列表
function StockAllotIndex(params) {
  return (0, _request.default)({
    url: '/StockAllot/index',
    method: 'get',
    params: params
  });
}

// 配件资料->所有的下拉
function StockPartCommon(params) {
  return (0, _request.default)({
    url: '/StockPart/common',
    method: 'get',
    params: params
  });
}

// 仓库管理->调拨单配件选择
function StockPartShow(params) {
  return (0, _request.default)({
    url: '/StockPart/show',
    method: 'get',
    params: params
  });
}

// 公用接口->获取code码
function StockCodeIndex(params) {
  return (0, _request.default)({
    url: '/StockCode/index',
    method: 'get',
    params: params
  });
}

// 仓库管理->调拨单保存
function StockAllotSave(data) {
  return (0, _request.default)({
    url: '/StockAllot/save',
    method: 'post',
    data: data
  });
}

// 调拨单->调拨单查看
function StockAllotShow(params) {
  return (0, _request.default)({
    url: '/StockAllot/show',
    method: 'get',
    params: params
  });
}

// 调拨单->调拨单删除
function StockAllotDelete(data) {
  return (0, _request.default)({
    url: '/StockAllot/delete',
    method: 'post',
    data: data
  });
}

// 线上销售->销售列表
function SaleOnlineList(params) {
  return (0, _request.default)({
    url: '/SaleOnline/saleList',
    method: 'get',
    params: params
  });
}

// 其他出入库保存
function StockOtherSave(data) {
  return (0, _request.default)({
    url: '/StockOther/save',
    method: 'post',
    data: data
  });
}

// 出入库->出库管理列表
function StockOutIndex(params) {
  return (0, _request.default)({
    url: '/StockOut/index',
    method: 'get',
    params: params
  });
}

// 出入库->出库管理查看
function StockOutShow(params) {
  return (0, _request.default)({
    url: '/StockOut/show',
    method: 'get',
    params: params
  });
}

// 出入库->出库管理保存
function StockOutSave(data) {
  return (0, _request.default)({
    url: '/StockOut/save',
    method: 'post',
    data: data
  });
}

// 出入库->入库管理确认
function StockInSave(data) {
  return (0, _request.default)({
    url: '/StockIn/save',
    method: 'post',
    data: data
  });
}

// 仓库信息管理->分类列表
function StockCategoryIndex(params) {
  return (0, _request.default)({
    url: '/StockCategory/index',
    method: 'get',
    params: params
  });
}

// 仓库信息管理->分类保存
function StockCategorySave(data) {
  return (0, _request.default)({
    url: '/StockCategory/save',
    method: 'post',
    data: data
  });
}

// 仓库信息管理->分类删除
function StockCategoryDelete(data) {
  return (0, _request.default)({
    url: '/StockCategory/delete',
    method: 'post',
    data: data
  });
}

// 仓库信息管理->分类批量删除
function StockCategoryBashDelete(data) {
  return (0, _request.default)({
    url: '/StockCategory/bashDelete',
    method: 'post',
    data: data
  });
}

// 配件列表->配件单数据展示
function StockPartRead(params) {
  return (0, _request.default)({
    url: '/StockPart/read',
    method: 'get',
    params: params
  });
}

// 仓库->仓库单个数据
function StockWarehouseRead(params) {
  return (0, _request.default)({
    url: '/StockWarehouse/read',
    method: 'get',
    params: params
  });
}

// 仓库->区域单个数据
function StockRegionRead(params) {
  return (0, _request.default)({
    url: '/StockRegion/read',
    method: 'get',
    params: params
  });
}

// 仓库->单位单个展示
function StockUnitRead(params) {
  return (0, _request.default)({
    url: '/StockUnit/read',
    method: 'get',
    params: params
  });
}

// 仓库->品牌单个展示
function StockBrandRead(params) {
  return (0, _request.default)({
    url: '/StockBrand/read',
    method: 'get',
    params: params
  });
}

// 仓库->工具单个显示
function StockToolRead(params) {
  return (0, _request.default)({
    url: '/StockTool/read',
    method: 'get',
    params: params
  });
}

// 仓库->货架单个数据
function StockShelvesRead(params) {
  return (0, _request.default)({
    url: '/StockShelves/read',
    method: 'get',
    params: params
  });
}

// 库存查询->配件列表
function StockPartList(params) {
  return (0, _request.default)({
    url: '/StockPart/list',
    method: 'get',
    params: params
  });
}

// 新的配件列表接口
function StockPartListSearch(params) {
  return (0, _request.default)({
    url: '/StockPart/listSearch',
    method: 'get',
    params: params
  });
}

// 库存查询->工具列表
function StockToolList(params) {
  return (0, _request.default)({
    url: '/StockTool/list',
    method: 'get',
    params: params
  });
}

// 盘点单->盘点单列表
function StockInventoryIndex(params) {
  return (0, _request.default)({
    url: '/StockInventory/index',
    method: 'get',
    params: params
  });
}

// 工具出库->列表
function StockToolsOutIndex(params) {
  return (0, _request.default)({
    url: '/StockToolsOut/index',
    method: 'get',
    params: params
  });
}
//删除工具出库->列表
function toooloutDelete(params) {
  return (0, _request.default)({
    url: 'StockToolsOut/delete',
    method: 'post',
    params: params
  });
}
//批量删除工具出库->列表
function toooloutbatchDelete(params) {
  return (0, _request.default)({
    url: 'StockToolsOut/batchDelete',
    method: 'post',
    params: params
  });
}

// 工具出库->出库单详情
function StockToolsOutShow(params) {
  return (0, _request.default)({
    url: '/StockToolsOut/show',
    method: 'get',
    params: params
  });
}

// 工具出库->出库单保存
function StockToolsOutSave(data) {
  return (0, _request.default)({
    url: '/StockToolsOut/save',
    method: 'post',
    data: data
  });
}

// 盘点->盘点单保存
function StockInventorySave(data) {
  return (0, _request.default)({
    url: '/StockInventory/save',
    method: 'post',
    data: data
  });
}

// 库存盘点根据区域获取配件列表
function StockInventoryPartList(params) {
  return (0, _request.default)({
    url: '/StockInventory/part',
    method: 'get',
    params: params
  });
}

// 盘点->盘点单详情
function StockInventoryShow(params) {
  return (0, _request.default)({
    url: '/StockInventory/show',
    method: 'get',
    params: params
  });
}

// 盘点单->盘点单确认
function StockInventoryConfirm(data) {
  return (0, _request.default)({
    url: '/StockInventory/confirm',
    method: 'post',
    data: data
  });
}

// 盘点->盘点单删除
function StockInventoryDelete(data) {
  return (0, _request.default)({
    url: '/StockInventory/delete',
    method: 'post',
    data: data
  });
}

// 根据仓库获取到区域
function StockCommonRegion(params) {
  return (0, _request.default)({
    url: '/StockCommon/region',
    method: 'get',
    params: params
  });
}

// 区域id来找货架
function StockCommonShelves(params) {
  return (0, _request.default)({
    url: '/StockCommon/shelves',
    method: 'get',
    params: params
  });
}

// 入库管理->入库明细列表
function StockInDetailIndex(params) {
  return (0, _request.default)({
    url: '/StockInDetail/index',
    method: 'get',
    params: params
  });
}

// 入库管理->每条入库的明细
function StockInDetailShow(params) {
  return (0, _request.default)({
    url: '/StockInDetail/show',
    method: 'get',
    params: params
  });
}

// 出库管理->出库明细列表
function StockOutDetailIndex(params) {
  return (0, _request.default)({
    url: '/StockOutDetail/index',
    method: 'get',
    params: params
  });
}

// 出库管理->每条出库的明细
function StockOutDetailShow(params) {
  return (0, _request.default)({
    url: '/StockOutDetail/show',
    method: 'get',
    params: params
  });
}

// 货位维护单确认
function StockCirculationConfirm(data) {
  return (0, _request.default)({
    url: '/StockCirculation/confirm',
    method: 'post',
    data: data
  });
}

// 加价管理->列表
function StockPriceUpIndex(params) {
  return (0, _request.default)({
    url: '/StockPriceUp/index',
    method: 'get',
    params: params
  });
}

// 加价管理-删除
function StockPriceUpDelete(data) {
  return (0, _request.default)({
    url: '/StockPriceUp/delete',
    method: 'post',
    data: data
  });
}

// 加价管理-批量删除
function StockPriceUpBatchDelete(data) {
  return (0, _request.default)({
    url: '/StockPriceUp/batchDelete',
    method: 'post',
    data: data
  });
}

// 加价管理->详情
function StockPriceUpRead(params) {
  return (0, _request.default)({
    url: '/StockPriceUp/read',
    method: 'get',
    params: params
  });
}

// 加价管理->添加修改
function StockPriceUpBatchSave(data) {
  return (0, _request.default)({
    url: '/StockPriceUp/save',
    method: 'post',
    data: data
  });
}

// 拉取配件指定货位下的库存
function StockCommonQuantity(data) {
  return (0, _request.default)({
    url: '/StockCommon/quantity',
    method: 'post',
    data: data
  });
}

// 出库时获取库存
function StockPartCountNum(params) {
  return (0, _request.default)({
    url: '/StockPart/countNum',
    method: 'get',
    params: params
  });
}

// 获取相关人员列表 type =1采购客服,2鉴定员,3接待员,4仓库人员,5销售员,6维修技师
function getStaffNames(params) {
  return (0, _request.default)({
    url: '/Supplier/getStaffNames',
    method: 'get',
    params: params
  });
}

// 入库时选仓库自动显示区域和货架
function getLocations(params) {
  return (0, _request.default)({
    url: '/StockCommon/locations',
    method: 'get',
    params: params
  });
}

//获取某个配件的某些价格
function getPartRead(params) {
  return (0, _request.default)({
    url: "/StockPart/read",
    method: 'get',
    params: params
  });
}