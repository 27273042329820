var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative", "margin-top": "-20px" } },
    [
      _c(
        "div",
        {
          staticClass: "wrapper2",
          style: { "margin-top": _vm.margin + "px" },
          attrs: { id: "doubleScroll-" + _vm.num },
          on: { scroll: _vm.handleScroll },
        },
        [
          _c("div", {
            staticClass: "width-scroll",
            style: { width: _vm.width + "px" },
            attrs: { id: "div2" },
          }),
        ]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }