var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            "show-file-list": false,
            accept: "image/*",
            "on-success": _vm.handleUploadSuccess,
            action: _vm.uploadAction,
            headers: _vm.uploadHeader,
            "file-list": _vm.fileList,
            "auto-upload": true,
            "list-type": "picture",
            multiple: false,
          },
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("点击上传"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.imageUrl
        ? _c("el-image", {
            staticStyle: { "max-height": "200px", "margin-top": "10px" },
            attrs: { src: _vm.imageUrl, fit: "contain" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }