"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  name: '亚马逊:',
  title1: '易趣：888',
  title2: '速卖通：777',
  label1: 'ID',
  label2: '渠道来源',
  label3: '渠道SKU',
  label4: '销售人员',
  label5: '刊登时间'
};
var _default = languagePack;
exports.default = _default;