"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
_axios.default.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
var service = _axios.default.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_SERVER2_API,
  // 超时
  timeout: 5 * 60 * 1000
});
// request拦截器
service.interceptors.request.use(function (config) {
  // if (getToken()) {
  //   config.headers['X-AUTH-TOKEN'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  // config.headers['Access-Token'] = 'EpHrzglkKTqjLuTdGukKfImFmXUZCbwJ'
  // config.headers['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9xZi50ZXN0XC9hcGlcL3YxXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY1MDg2OTI2MSwiZXhwIjoxNjU2MDUzMjYxLCJuYmYiOjE2NTA4NjkyNjEsImp0aSI6InZORU1JRGhrS3ZIcWxEdW8iLCJzdWIiOjEsInBydiI6ImRmODgzZGI5N2JkMDVlZjhmZjg1MDgyZDY4NmM0NWU4MzJlNTkzYTkifQ.JMS08oZEVKAG8FBjvu2vm1fQiH9n5rRZCF-CqCFua6w'
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  var url = localStorage.getItem('baseUrl2');
  if (url) {
    config.url = url + config.url;
  }
  return config;
}, function (error) {
  console.log(error);
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (res) {
  var code = res.data.code;
  // console.log('响应拦截器 res:', res);
  if (code === 4001) {
    _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
  } else if (code == 0) {
    _elementUi.Message.error(res.data.msg);
    return Promise.reject('error');
  } else {
    return res.data;
  }
}, function (error) {
  console.log('err' + error);
  (0, _elementUi.Message)({
    message: error.message == 'timeout of 10000ms exceeded' ? '网络连接超时(> 10s)' : error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;