"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Categoryindex = Categoryindex;
exports.Categorysearch = Categorysearch;
exports.ChannelSkusave = ChannelSkusave;
exports.Department_list = Department_list;
exports.LogisticPakage = LogisticPakage;
exports.ProductDeclare = ProductDeclare;
exports.ProductEdit = ProductEdit;
exports.ProductRemark = ProductRemark;
exports.ProductTranslate = ProductTranslate;
exports.Product_edit = Product_edit;
exports.Productindex = Productindex;
exports.RoleGroup = RoleGroup;
exports.Specindex = Specindex;
exports.Specshow = Specshow;
exports.SupProblemindex = SupProblemindex;
exports.Suplier = Suplier;
exports.Suplierindex = Suplierindex;
exports.User = User;
exports.addSupPayee = addSupPayee;
exports.addSupPrice = addSupPrice;
exports.addUser = addUser;
exports.addrole = addrole;
exports.addroleGroup = addroleGroup;
exports.addserver = addserver;
exports.bacthDelLogistic = bacthDelLogistic;
exports.bacthExportSup = bacthExportSup;
exports.batchAssignAddress = batchAssignAddress;
exports.batchAssignBuyManage = batchAssignBuyManage;
exports.batchAssignBuyUser = batchAssignBuyUser;
exports.batchAssignDevUser = batchAssignDevUser;
exports.batchAssignPayType = batchAssignPayType;
exports.batchAssignUsing = batchAssignUsing;
exports.batchAssignWarehouse = batchAssignWarehouse;
exports.batchAssinBlack = batchAssinBlack;
exports.batchAssinEndTime = batchAssinEndTime;
exports.batchDelete = batchDelete;
exports.batchManageReview = batchManageReview;
exports.batchSubmitReview = batchSubmitReview;
exports.batchgetSku = batchgetSku;
exports.batchgetSpu = batchgetSpu;
exports.bySupPrice = bySupPrice;
exports.changeSup = changeSup;
exports.channelSkuindex = channelSkuindex;
exports.channeldel = channeldel;
exports.channelindex = channelindex;
exports.checkDetail = checkDetail;
exports.checkPro = checkPro;
exports.checkProduct = checkProduct;
exports.city = city;
exports.clearTaxRate = clearTaxRate;
exports.countryList = countryList;
exports.create = create;
exports.createLogistic = createLogistic;
exports.dataDelete = dataDelete;
exports.dataImport = dataImport;
exports.dataIndex = dataIndex;
exports.declaresave = declaresave;
exports.deleteSupPayee = deleteSupPayee;
exports.deleteSupPrice = deleteSupPrice;
exports.delrole = delrole;
exports.delroleGroup = delroleGroup;
exports.editProPrice = editProPrice;
exports.editSupPayee = editSupPayee;
exports.editUser = editUser;
exports.editroleGroup = editroleGroup;
exports.execTranslate = execTranslate;
exports.getBanned = getBanned;
exports.getShipPackage = getShipPackage;
exports.getSkuInfo = getSkuInfo;
exports.getUserList = getUserList;
exports.getVoidProduct = getVoidProduct;
exports.getWarehouse = getWarehouse;
exports.platformList = platformList;
exports.proBasicInfo = proBasicInfo;
exports.proUnitList = proUnitList;
exports.province = province;
exports.role = role;
exports.saveProduct = saveProduct;
exports.saveRemark = saveRemark;
exports.saveTranslate = saveTranslate;
exports.searchOrder = searchOrder;
exports.selectSkuSup = selectSkuSup;
exports.serverindex = serverindex;
exports.setArtUser = setArtUser;
exports.setBanned = setBanned;
exports.setBuyUser = setBuyUser;
exports.setCheckUser = setCheckUser;
exports.setDefaultPayee = setDefaultPayee;
exports.setDevUser = setDevUser;
exports.setEditUser = setEditUser;
exports.setLogistAttr = setLogistAttr;
exports.setPhotograph = setPhotograph;
exports.setProAttr = setProAttr;
exports.setProOrign = setProOrign;
exports.setProRefuse = setProRefuse;
exports.setProRemark = setProRemark;
exports.setProSize = setProSize;
exports.setProStatus = setProStatus;
exports.setProType = setProType;
exports.setProWeight = setProWeight;
exports.setShipPackage = setShipPackage;
exports.setSupPrice = setSupPrice;
exports.setSupPriceDefault = setSupPriceDefault;
exports.setUser = setUser;
exports.setVoid = setVoid;
exports.shopChannel = shopChannel;
exports.submitCheck = submitCheck;
exports.supPayeeList = supPayeeList;
exports.supPriceInfo = supPriceInfo;
exports.supcontactcreate = supcontactcreate;
exports.supcontactdelete = supcontactdelete;
exports.supcontactedit = supcontactedit;
exports.supcontactindex = supcontactindex;
exports.supdelete = supdelete;
exports.suplieredit = suplieredit;
exports.updaterole = updaterole;
exports.voidProManage = voidProManage;
var _request = _interopRequireDefault(require("@/utils/request"));
// 供应商列表
function Suplier(data) {
  return (0, _request.default)({
    url: '/Suplier/index',
    method: 'get',
    params: data
  });
}

// 新增供应商
function create(data) {
  return (0, _request.default)({
    url: 'Suplier/create',
    method: 'post',
    data: data
  });
}

// 删除供应商
function supdelete(data) {
  return (0, _request.default)({
    url: 'Suplier/delete',
    method: 'post',
    data: data
  });
}

// 批量删除供应商
function batchDelete(data) {
  return (0, _request.default)({
    url: 'Suplier/batchDelete',
    method: 'post',
    data: data
  });
}

// 供应商库筛选
function Suplierindex(data) {
  return (0, _request.default)({
    url: 'Suplier/index',
    method: 'get',
    params: data
  });
}

// 供应商批量提交审核
function batchSubmitReview(data) {
  return (0, _request.default)({
    url: 'Suplier/batchSubmitReview',
    method: 'post',
    data: data
  });
}

// 供应商批量主管审核
function batchManageReview(data) {
  return (0, _request.default)({
    url: 'Suplier/batchManageReview',
    method: 'post',
    data: data
  });
}

// 仓库下拉
function getWarehouse(data) {
  return (0, _request.default)({
    url: 'SelectCondition/getWarehouse',
    method: 'get',
    params: data
  });
}

// 问题处理列表
function SupProblemindex(data) {
  return (0, _request.default)({
    url: 'SupProblem/index',
    method: 'get',
    params: data
  });
}

// 供应商库修改
function suplieredit(data) {
  return (0, _request.default)({
    url: 'Suplier/edit',
    method: 'post',
    data: data
  });
}

// 供应商联系人列表
function supcontactindex(data) {
  return (0, _request.default)({
    url: 'Supcontact/index',
    method: 'get',
    params: data
  });
}

// 新增供应商联系人
function supcontactcreate(data) {
  return (0, _request.default)({
    url: 'Supcontact/create',
    method: 'post',
    data: data
  });
}

// 删除供应商联系人
function supcontactdelete(data) {
  return (0, _request.default)({
    url: 'Supcontact/delete',
    method: 'post',
    data: data
  });
}

// 编辑供应商联系人
function supcontactedit(data) {
  return (0, _request.default)({
    url: 'Supcontact/edit',
    method: 'post',
    data: data
  });
}

// 批量分配采购人员
function batchAssignBuyUser(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssignBuyUser',
    method: 'post',
    data: data
  });
}

// 批量分配开发人员
function batchAssignDevUser(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssignDevUser',
    method: 'post',
    data: data
  });
}

// 批量分配采购经理
function batchAssignBuyManage(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssignBuyManage',
    method: 'post',
    data: data
  });
}

// 批量设置供应商发货地
function batchAssignAddress(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssignAddress',
    method: 'post',
    data: data
  });
}

// 批量设置发货仓库
function batchAssignWarehouse(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssignWarehouse',
    method: 'post',
    data: data
  });
}

// 批量设置黑名单
function batchAssinBlack(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssinBlack',
    method: 'post',
    data: data
  });
}

// 批量设置结算时间
function batchAssinEndTime(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssinEndTime',
    method: 'post',
    data: data
  });
}

// 批量设置付款方式
function batchAssignPayType(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssignPayType',
    method: 'post',
    data: data
  });
}

// 批量设置启用状态
function batchAssignUsing(data) {
  return (0, _request.default)({
    url: 'Suplier/batchAssignUsing',
    method: 'post',
    data: data
  });
}

// 产品价格信息列表
function supPriceInfo(data) {
  return (0, _request.default)({
    url: 'Product/supPriceInfo',
    method: 'get',
    params: data
  });
}

// 新增产品供应商价格
function addSupPrice(data) {
  return (0, _request.default)({
    url: 'Product/addSupPrice',
    method: 'post',
    data: data
  });
}

// 编辑产品价格  
function editProPrice(data) {
  return (0, _request.default)({
    url: 'Product/editProPrice',
    method: 'post',
    data: data
  });
}

// 设置供应商价格为默认
function setSupPriceDefault(data) {
  return (0, _request.default)({
    url: 'Product/setSupPriceDefault',
    method: 'post',
    data: data
  });
}

// 报关信息列表
function ProductDeclare(data) {
  return (0, _request.default)({
    url: 'ProductDeclare/index',
    method: 'get',
    params: data
  });
}

// 报关信息修改  
function declaresave(data) {
  return (0, _request.default)({
    url: 'ProductDeclare/save',
    method: 'post',
    data: data
  });
}

// 产品编辑查询信息
function ProductEdit(data) {
  return (0, _request.default)({
    url: 'ProductEdit/index',
    method: 'get',
    params: data
  });
}

// 产品编辑修改信息
function saveProduct(data) {
  return (0, _request.default)({
    url: 'ProductEdit/save',
    method: 'post',
    data: data
  });
}

// 产品备注信息
function ProductRemark(data) {
  return (0, _request.default)({
    url: 'ProductRemark/index',
    method: 'get',
    params: data
  });
}

// 修改备注信息
function saveRemark(data) {
  return (0, _request.default)({
    url: 'ProductRemark/save',
    method: 'post',
    data: data
  });
}

// 翻译列表
function ProductTranslate(data) {
  return (0, _request.default)({
    url: 'ProductTranslate/index',
    method: 'get',
    params: data
  });
}

// 翻译接口  
function execTranslate(data) {
  return (0, _request.default)({
    url: 'ProductTranslate/exec',
    method: 'post',
    data: data
  });
}

// 翻译修改
function saveTranslate(data) {
  return (0, _request.default)({
    url: 'ProductTranslate/save',
    method: 'post',
    data: data
  });
}

// 收款人列表
function supPayeeList(data) {
  return (0, _request.default)({
    url: 'Suplier/supPayeeList',
    method: 'get',
    params: data
  });
}

// 收款人添加
function addSupPayee(data) {
  return (0, _request.default)({
    url: 'Suplier/addSupPayee',
    method: 'post',
    data: data
  });
}

// 收款人删除
function deleteSupPayee(data) {
  return (0, _request.default)({
    url: 'Suplier/deleteSupPayee',
    method: 'post',
    data: data
  });
}

// 收款人修改
function editSupPayee(data) {
  return (0, _request.default)({
    url: 'Suplier/editSupPayee',
    method: 'post',
    data: data
  });
}

// 产品库列表
function Productindex(data) {
  return (0, _request.default)({
    url: 'Product/index',
    method: 'get',
    params: data
  });
}

// 物流包材列表
function LogisticPakage(data) {
  return (0, _request.default)({
    url: 'LogisticPakage/index',
    method: 'get',
    params: data
  });
}

// 新增物流包材   
function createLogistic(data) {
  return (0, _request.default)({
    url: 'LogisticPakage/create',
    method: 'post',
    data: data
  });
}
// 批量删除包材  
function bacthDelLogistic(data) {
  return (0, _request.default)({
    url: 'LogisticPakage/bacthDelete',
    method: 'post',
    data: data
  });
}

// 上传文件列表
function dataIndex(data) {
  return (0, _request.default)({
    url: 'Suplier/dataIndex',
    method: 'get',
    params: data
  });
}

// 上传文件资料
function dataImport(data) {
  return (0, _request.default)({
    url: 'Suplier/dataImport',
    method: 'post',
    data: data
  });
}

// 删除上传文件 
function dataDelete(data) {
  return (0, _request.default)({
    url: 'Suplier/dataDelete',
    method: 'post',
    data: data
  });
}

// 审核产品
function checkProduct(data) {
  return (0, _request.default)({
    url: 'Product/checkProduct',
    method: 'post',
    data: data
  });
}

// 获取sku 
function getSkuInfo(data) {
  return (0, _request.default)({
    url: 'ProductBacth/getSkuInfo',
    method: 'get',
    params: data
  });
}

// 批量设置供应价
function setSupPrice(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setSupPrice',
    method: 'post',
    data: data
  });
}

// 批量设置产品性质  
function setProAttr(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProAttr',
    method: 'post',
    data: data
  });
}

// 获取产品基础信息   
function proBasicInfo(data) {
  return (0, _request.default)({
    url: 'Product/proBasicInfo',
    method: 'get',
    params: data
  });
}

// 批量设置物流属性
function setLogistAttr(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setLogistAttr',
    method: 'post',
    data: data
  });
}

// 批量设置产品类型  
function setProType(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProType',
    method: 'post',
    data: data
  });
}

// 批量设置产品重量  
function setProWeight(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProWeight',
    method: 'post',
    data: data
  });
}

// 批量设置产品尺寸   
function setProSize(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProSize',
    method: 'post',
    data: data
  });
}

// 批量设置产品来源  
function setProOrign(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProOrign',
    method: 'post',
    data: data
  });
}

// 批量设置拍照 
function setPhotograph(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setPhotograph',
    method: 'post',
    data: data
  });
}

// 根据供货价设置   
function bySupPrice(data) {
  return (0, _request.default)({
    url: 'ProductBacth/bySupPrice',
    method: 'post',
    data: data
  });
}

// 批量导入供应商
function bacthExportSup(data) {
  return (0, _request.default)({
    url: 'Suplier/bacthExportSup',
    method: 'post',
    data: data
  });
}

// 设置默认收款人
function setDefaultPayee(data) {
  return (0, _request.default)({
    url: 'Suplier/setDefaultPayee',
    method: 'post',
    data: data
  });
}

// 类型搜索
function Categorysearch(data) {
  return (0, _request.default)({
    url: 'Category/search',
    method: 'get',
    params: data
  });
}

// 基本信息修改
function Product_edit(data) {
  return (0, _request.default)({
    url: 'Product/edit',
    method: 'post',
    data: data
  });
}

// 产品库审核
function checkDetail(data) {
  return (0, _request.default)({
    url: 'Product/checkDetail',
    method: 'get',
    params: data
  });
}

// 获取作废sku列表
function getVoidProduct(data) {
  return (0, _request.default)({
    url: 'ProductBacth/getVoidProduct',
    method: 'get',
    params: data
  });
}

// 作废产品管理 
function voidProManage(data) {
  return (0, _request.default)({
    url: 'ProductBacth/voidProManage',
    method: 'post',
    data: data
  });
}

// 规格管理    
function Specindex(data) {
  return (0, _request.default)({
    url: 'Spec/index',
    method: 'get',
    params: data
  });
}

// 批量设置修改产品备注
function setProRemark(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProRemark',
    method: 'post',
    data: data
  });
}

// 批量清除退税率
function clearTaxRate(data) {
  return (0, _request.default)({
    url: 'ProductBacth/clearTaxRate',
    method: 'post',
    data: data
  });
}

// 批量提取spu  
function batchgetSpu(data) {
  return (0, _request.default)({
    url: 'ProductBacth/getSpu',
    method: 'get',
    params: data
  });
}

// 批量提取sku    
function batchgetSku(data) {
  return (0, _request.default)({
    url: 'ProductBacth/getSku',
    method: 'get',
    params: data
  });
}

// 批量设置产品状态   
function setProStatus(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProStatus',
    method: 'post',
    data: data
  });
}

// 批量设置产品不通过   
function setProRefuse(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setProRefuse',
    method: 'post',
    data: data
  });
}

// 批量设置审核人
function setCheckUser(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setCheckUser',
    method: 'post',
    data: data
  });
}

// 批量分配知产人员
function setUser(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setUser',
    method: 'post',
    data: data
  });
}

// 批量分配编辑人员
function setEditUser(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setEditUser',
    method: 'post',
    data: data
  });
}

// 批量设置采购人员  
function setBuyUser(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setBuyUser',
    method: 'post',
    data: data
  });
}

// 批量分配美工 
function setArtUser(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setArtUser',
    method: 'post',
    data: data
  });
}

// 批量审核通过   
function checkPro(data) {
  return (0, _request.default)({
    url: 'ProductBacth/checkPro',
    method: 'post',
    data: data
  });
}

// 批量作废   
function setVoid(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setVoid',
    method: 'post',
    data: data
  });
}

// 批量设置包材  
function setShipPackage(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setShipPackage',
    method: 'post',
    data: data
  });
}

// 产品库包材回显    
function getShipPackage(data) {
  return (0, _request.default)({
    url: 'ProductBacth/getShipPackage',
    method: 'get',
    params: data
  });
}

// 规格属性
function Specshow(data) {
  return (0, _request.default)({
    url: 'Spec/show',
    method: 'get',
    params: data
  });
}

// 删除供应商价    
function deleteSupPrice(data) {
  return (0, _request.default)({
    url: 'Product/deleteSupPrice',
    method: 'post',
    data: data
  });
}

// 禁售平台    
function setBanned(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setBanned',
    method: 'post',
    data: data
  });
}

// 批量设置供应商列表    
function selectSkuSup(data) {
  return (0, _request.default)({
    url: 'ProductBacth/selectSkuSup',
    method: 'get',
    params: data
  });
}

// 批量更换供应商    	
function changeSup(data) {
  return (0, _request.default)({
    url: 'ProductBacth/changeSup',
    method: 'post',
    data: data
  });
}

// 禁售回显   
function getBanned(data) {
  return (0, _request.default)({
    url: 'ProductBacth/getBanned',
    method: 'get',
    params: data
  });
}

// 获取平台列表
function platformList(data) {
  return (0, _request.default)({
    url: 'SelectCondition/platformList',
    method: 'get',
    params: data
  });
}

// 获取国家列表
function countryList(data) {
  return (0, _request.default)({
    url: 'SelectCondition/countryList',
    method: 'get',
    params: data
  });
}

// 类目
function Categoryindex(data) {
  return (0, _request.default)({
    url: 'Category/index',
    method: 'get',
    params: data
  });
}

// 人员下拉
function getUserList(data) {
  return (0, _request.default)({
    url: 'User/getUserList',
    method: 'get',
    params: data
  });
}

// 批量设置开发人员(产品)
function setDevUser(data) {
  return (0, _request.default)({
    url: 'ProductBacth/setDevUser',
    method: 'post',
    data: data
  });
}

// 批量提交审核
function submitCheck(data) {
  return (0, _request.default)({
    url: 'ProductBacth/submitCheck',
    method: 'post',
    data: data
  });
}

// 地址 省   
function province(data) {
  return (0, _request.default)({
    url: 'Address/province',
    method: 'get',
    params: data
  });
}

// 地址 市
function city(data) {
  return (0, _request.default)({
    url: 'Address/city',
    method: 'get',
    params: data
  });
}

// 产品接口下拉
function proUnitList(data) {
  return (0, _request.default)({
    url: 'SelectCondition/proUnitList',
    method: 'get',
    params: data
  });
}

// 账号
// 渠道sku规则列表
function channelindex(data) {
  return (0, _request.default)({
    url: '/ChannelSkuRule',
    method: 'get',
    params: data
  });
}

// 渠道sku规则列表删除
function channeldel(data) {
  return (0, _request.default)({
    url: '/ChannelSkuRule/0',
    method: 'delete',
    params: data
  });
}

// 渠道sku关联列表
function channelSkuindex(data) {
  return (0, _request.default)({
    url: '/ChannelSkuCombination',
    method: 'get',
    params: data
  });
}

// 新增渠道sku关联
function ChannelSkusave(data) {
  return (0, _request.default)({
    url: '/ChannelSkuCombination',
    method: 'post',
    params: data
  });
}

// 搜索sku相关订单接口
function searchOrder(data) {
  return (0, _request.default)({
    url: '/ChannelSkuCombination/searchOrder',
    method: 'get',
    params: data
  });
}

// 服务器列表
function serverindex(data) {
  return (0, _request.default)({
    url: "/Server",
    method: 'get',
    params: data
  });
}

// 绑定渠道下拉
function shopChannel(data) {
  return (0, _request.default)({
    url: "/ShopChannel",
    method: 'get',
    params: data
  });
}

// 新增服务器信息
function addserver(data) {
  return (0, _request.default)({
    url: "/Server",
    method: 'post',
    params: data
  });
}

// 角色组列表
function RoleGroup(data) {
  return (0, _request.default)({
    url: "/Rolegroup",
    method: 'get',
    params: data
  });
}

// 新增角色组 
function addroleGroup(data) {
  return (0, _request.default)({
    url: "/Rolegroup",
    method: 'post',
    data: data
  });
}

// 编辑角色组
function editroleGroup(data, id) {
  return (0, _request.default)({
    url: '/Rolegroup/' + id,
    method: 'put',
    data: data
  });
}

// 删除角色组
function delroleGroup(id, data) {
  return (0, _request.default)({
    url: '/Rolegroup/' + id,
    method: 'delete',
    data: data
  });
}

// 角色列表
function role(data) {
  return (0, _request.default)({
    url: "/role",
    method: 'get',
    params: data
  });
}

// 新增角色
function addrole(data) {
  return (0, _request.default)({
    url: "/role",
    method: 'post',
    params: data
  });
}

// 编辑角色
function updaterole(data, id) {
  return (0, _request.default)({
    url: '/Role/' + id,
    method: 'put',
    data: data
  });
}

// 删除角色
function delrole(id, data) {
  return (0, _request.default)({
    url: '/Role/' + id,
    method: 'delete',
    data: data
  });
}

// 用户列表
function User(data) {
  return (0, _request.default)({
    url: "/User",
    method: 'get',
    params: data
  });
}

// 新增用户
function addUser(data) {
  return (0, _request.default)({
    url: "/User",
    method: 'post',
    data: data
  });
}

// 编辑用户
function editUser(data, id) {
  return (0, _request.default)({
    url: "/User/" + id,
    method: 'put',
    data: data
  });
}

// 部门列表的列表
function Department_list(data) {
  return (0, _request.default)({
    url: 'Department/list',
    method: 'get',
    params: data
  });
}