var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { "margin-left": "30px" },
      attrs: {
        size: "small",
        disabled: _vm.disabled,
        filterable: "",
        remote: "",
        placeholder: "请输入关键字搜索",
        "remote-method": _vm.getSupplierNameList,
      },
      on: { change: _vm.setSupplier },
      model: {
        value: _vm.form.supplier_id,
        callback: function ($$v) {
          _vm.$set(_vm.form, "supplier_id", $$v)
        },
        expression: "form.supplier_id",
      },
    },
    _vm._l(_vm.supplierList, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: { label: item.supplier_name, value: item.id },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }