"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  nickname: "投诉平台",
  nick1: "侵权类型",
  nick2: "品牌",
  nick3: "验证结果",
  nick4: "详情查询"
};
var _default = languagePack;
exports.default = _default;