"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
var _dt = require("@/api/dt");
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "WareHouseChoose",
  data: function data() {
    return {
      storageList: []
    };
  },
  props: {
    form: {
      type: Object,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  activated: function activated() {},
  methods: {
    // 关键字搜索供应商
    getSupplierNameList: function getSupplierNameList(query) {
      var _this = this;
      console.log("搜索-------", query);
      (0, _dt.getSupplierName)({
        key: query
      }).then(function (res) {
        console.log("关键字搜索供应商----", res);
        _this.supplierList = res.data;
      });
    },
    // 选择供应商
    setSupplier: function setSupplier(data) {
      var _this2 = this;
      this.form.contact_name = "";
      this.form.contact_phone = "";
      this.form.invoice_type = "";
      this.form.pay_type = "";
      this.form.initial_arrears = "";
      console.log("供应商修改---", data);
      var index = this.supplierList.findIndex(function (e) {
        return e.id == data;
      });
      console.log("供应商修改的index:", index);
      this.form.supplier_name = this.supplierList[index].supplier_name;
      supplierInfo({
        id: this.form.supplier_id
      }).then(function (res) {
        console.log("供应商详情:", res);
        _this2.form.contact_name = res.data.contact_name;
        _this2.form.contact_phone = res.data.contact_phone;
        _this2.form.invoice_type = res.data.invoice_type;
        _this2.form.pay_type = res.data.pay_type;
        _this2.form.initial_arrears = res.data.initial_arrears;
      });
    }
  }
};
exports.default = _default2;