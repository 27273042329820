"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker"));
var _auth = require("@/utils/auth");
var _pbw = require("@/api/pbw/pbw");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ThemePicker: _ThemePicker.default
  },
  data: function data() {
    return {
      theme: this.$store.state.settings.theme,
      sideTheme: this.$store.state.settings.sideTheme,
      imageUrl: '',
      uploadAction: process.env.VUE_APP_BASE_API + "CommonFunc/uploads",
      uploadHeader: {
        "x-auth-token": (0, _auth.getToken)(),
        "Access-Token": "YJGzmoKjkwGFsUeUAVxbFWHWTsxOMugA"
      },
      url: process.env.VUE_APP_BASE_API.replace(/\/api\//g, ''),
      loading: false
    };
  },
  computed: {
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    enableExport: {
      get: function get() {
        return this.$store.state.settings.enableExport;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'enableExport',
          value: val
        });
      }
    },
    IProperty: {
      get: function get() {
        return this.$store.state.settings.IProperty;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'IProperty',
          value: val
        });
      }
    },
    topNav: {
      get: function get() {
        return this.$store.state.settings.topNav;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'topNav',
          value: val
        });
        if (!val) {
          this.$store.commit("SET_SIDEBAR_ROUTERS", this.$store.state.permission.defaultRoutes);
        }
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    },
    dynamicTitle: {
      get: function get() {
        return this.$store.state.settings.dynamicTitle;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'dynamicTitle',
          value: val
        });
      }
    }
  },
  methods: {
    handleProgress: function handleProgress() {
      this.loading = true;
    },
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      var _this = this;
      console.log("%c Line:192 🥑 handleAvatarSuccess", "color:#ea7e5c", res);
      this.imageUrl = res.data[0].url;
      (0, _pbw.editUser)({
        avatar: res.data[0].path
      }, this.$store.state.user.userInfo.id).then(function (result) {
        _this.loading = false;
        if (result.code == 1) {
          _this.$message.success("头像修改成功");
          _this.$store.commit('SET_AVATAR', res.data[0].path);
        } else {
          _this.$message.error("头像修改失败，如果此问题持续存在，请反馈");
        }
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    themeChange: function themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      });
      this.theme = val;
    },
    handleTheme: function handleTheme(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'sideTheme',
        value: val
      });
      this.sideTheme = val;
    },
    saveSetting: function saveSetting() {
      this.$modal.loading("正在保存到本地，请稍候...");
      var obj = {
        "topNav": this.topNav,
        "tagsView": this.tagsView,
        "fixedHeader": this.fixedHeader,
        "sidebarLogo": this.sidebarLogo,
        "dynamicTitle": this.dynamicTitle,
        "sideTheme": this.sideTheme,
        "theme": this.theme,
        "enableExport": this.enableExport
      };
      this.$cache.local.set("layout-setting", JSON.stringify(obj));
      setTimeout(this.$modal.closeLoading(), 1000);
    },
    resetSetting: function resetSetting() {
      this.$modal.loading("正在清除设置缓存并刷新，请稍候...");
      this.$cache.local.remove("layout-setting");
      setTimeout("window.location.reload()", 1000);
    }
  },
  mounted: function mounted() {
    this.imageUrl = this.url + this.$store.state.user.userInfo.avatar;
  }
};
exports.default = _default;