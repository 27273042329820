"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _router = require("@/router");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      // 顶部栏初始数
      visibleNumber: 10,
      // 是否为首次加载
      isFrist: false,
      // 当前激活菜单的 index
      currentIndex: undefined
    };
  },
  computed: {
    theme: function theme() {
      return this.$store.state.settings.theme;
    },
    // 顶部显示菜单
    topMenus: function topMenus() {
      var topMenus = [];
      this.routers.map(function (menu) {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    },
    // 所有的路由信息
    routers: function routers() {
      return this.$store.state.permission.topbarRouters;
    },
    // 设置子路由
    childrenMenus: function childrenMenus() {
      var _this = this;
      var childrenMenus = [];
      this.routers.map(function (router) {
        for (var item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path = "/redirect/" + router.children[item].path;
            } else {
              if (!_this.ishttp(router.children[item].path)) {
                router.children[item].path = router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return _router.constantRoutes.concat(childrenMenus);
    },
    // 默认激活的菜单
    activeMenu: function activeMenu() {
      var path = this.$route.path;
      var activePath = this.defaultRouter();
      if (path.lastIndexOf("/") > 0) {
        var tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
      } else if ("/index" == path || "" == path) {
        if (!this.isFrist) {
          this.isFrist = true;
        } else {
          activePath = "index";
        }
      }
      var routes = this.activeRoutes(activePath);
      if (routes.length === 0) {
        activePath = this.currentIndex || this.defaultRouter();
        this.activeRoutes(activePath);
      }
      return activePath;
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.setVisibleNumber);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleNumber);
  },
  mounted: function mounted() {
    this.setVisibleNumber();
  },
  methods: {
    // 根据宽度计算设置显示栏数
    setVisibleNumber: function setVisibleNumber() {
      var width = document.body.getBoundingClientRect().width / 1.5;
      this.visibleNumber = parseInt(width / 85);
    },
    // 默认激活的路由
    defaultRouter: function defaultRouter() {
      var _this2 = this;
      var router;
      Object.keys(this.routers).some(function (key) {
        if (!_this2.routers[key].hidden) {
          router = _this2.routers[key].path;
          return true;
        }
      });
      return router;
    },
    // 菜单选择事件
    handleSelect: function handleSelect(key, keyPath) {
      console.log('%c 🥒 keyPath: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', keyPath);
      console.log('%c 🍼️ key: ', 'font-size:20px;background-color: #FCA650;color:#fff;', key);
      this.currentIndex = key;
      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (key.indexOf("/redirect") !== -1) {
        // /redirect 路径内部打开
        this.$router.push({
          path: key.replace("/redirect", "")
        });
      } else if (key == 'index') {
        this.$router.push({
          name: 'Index'
        });
      } else {
        // 显示左侧联动菜单
        this.activeRoutes(key);
        // 跳转到redirect页
        // this.handleRedirect()
        var fu = this.childrenMenus.filter(function (e) {
          return e.parentPath == key;
        });
        this.$router.push({
          path: "".concat(fu[0].path, "/").concat(fu[0].children[0].path)
        });
        console.log('%c 🌯 this.childrenMenus: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', this.childrenMenus);
        console.log('%c 🍢 fu: ', 'font-size:20px;background-color: #33A5FF;color:#fff;', fu);
      }
    },
    // 当前激活的路由
    activeRoutes: function activeRoutes(key) {
      var routes = [];
      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map(function (item) {
          if (key == item.parentPath || key == "index" && "" == item.path) {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        this.$store.commit("SET_SIDEBAR_ROUTERS", routes);
        console.log('%c 🍮 routes: ', 'font-size:20px;background-color: #42b983;color:#fff;', routes);
      }
      return routes;
    },
    ishttp: function ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1;
    }
  }
};
exports.default = _default;