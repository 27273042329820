var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      attrs: {
        prop: _vm.customProp,
        label: _vm.customTitle ? _vm.customTitle : _vm.title[_vm.type],
      },
    },
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "180px" },
          attrs: { size: "small", value: _vm.selectValue, clearable: "" },
          on: { change: _vm.handleValueChange },
        },
        _vm._l(_vm.typeOptions, function (item, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: item, value: item },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("input", {
        staticStyle: { display: "none" },
        attrs: { type: "number" },
        domProps: { value: _vm.value },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }