var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.deleteUI
        ? [
            _c("div", { staticClass: "form-title mg-b-20" }, [
              _vm.show
                ? _c(
                    "p",
                    { staticClass: "wrapperIcon", on: { click: _vm.setShow } },
                    [
                      _c("i", {
                        staticClass: "el-icon-caret-bottom",
                        staticStyle: {
                          color: "#409eff",
                          "margin-right": "5px",
                        },
                      }),
                      _vm._v(_vm._s(_vm.text) + "\n      "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.show
                ? _c(
                    "p",
                    { staticClass: "wrapperIcon", on: { click: _vm.setShow } },
                    [
                      _c("i", {
                        staticClass: "el-icon-caret-right",
                        staticStyle: {
                          color: "#409eff",
                          "margin-right": "5px",
                        },
                      }),
                      _vm._v(_vm._s(_vm.text) + "\n      "),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "slide-fade" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show,
                      expression: "show",
                    },
                  ],
                },
                [_vm._t("default")],
                2
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteUI ? [_vm._t("default")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }