"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "formTitle",
  props: {
    deleteUI: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      show: true
    };
  },
  methods: {
    setShow: function setShow() {
      this.show = !this.show;
    }
  },
  activated: function activated() {
    this.show = this.isShow;
  }
};
exports.default = _default;