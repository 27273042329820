var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      staticClass: "rtc-main",
    },
    [
      _c(
        "div",
        {
          staticClass: "JmY-JjNoFAuJg JmY-n87hfBo3A",
          attrs: { "data-log-module": "webrtc工具条" },
        },
        [
          _vm.callStatus == 1
            ? _c("div", { staticClass: "JmY-JEnPSwv_1 " }, [
                _c("div", { staticClass: "JmY-POzSUEy12" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.callStatus == 2
            ? _c("div", { staticClass: "JmY-JEnPSwv_1 JmY-oPPa2cgi7" }, [
                _vm._m(0),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.callStatus == 3
            ? _c("div", { staticClass: "JmY-JEnPSwv_1 active" }, [
                _c("div", { staticClass: "JmY-POzSUEy12" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "JmY-r3tqAtzzo" }, [
            _c(
              "span",
              {
                staticClass:
                  "leads-popper-trigger leads-popper-trigger-focus leads-tooltip",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "JmY-KOVNvfQIB ",
                    attrs: { id: "leads-webrtc-icon-loginText" },
                  },
                  [_vm._v(_vm._s(_vm.callMap[_vm.callStatus]))]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(1),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.queueSize.seatCount != -1
        ? _c("div", { staticClass: "infos" }, [
            _c(
              "div",
              {
                staticClass: "flex",
                staticStyle: { "justify-content": "space-around" },
              },
              [
                _c("div", [
                  _vm._v(
                    "坐席数: " +
                      _vm._s(
                        _vm.queueSize.seatCount == -1
                          ? "获取中"
                          : _vm.queueSize.seatCount
                      ) +
                      "个"
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "使用中: " +
                      _vm._s(
                        _vm.queueSize.activeUsers == -1
                          ? "获取中"
                          : _vm.queueSize.activeUsers
                      ) +
                      "个"
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex",
                staticStyle: { "justify-content": "space-around" },
              },
              [
                _c("div", [
                  _vm._v(
                    "排队中: " +
                      _vm._s(
                        _vm.queueSize.queue == -1
                          ? "获取中"
                          : _vm.queueSize.queue
                      ) +
                      "人"
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("队列中: " + _vm._s(_vm.queueSize.position) + "位"),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.queueSize.seatCount != -1
        ? _c(
            "div",
            { staticClass: "flex-box" },
            [
              _vm.callStatus == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.leaveQueue },
                    },
                    [_vm._v("离开队列")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "JmY-ZUDZq7z7M",
        attrs: { elementtiming: "element-timing" },
      },
      [
        _c("div", {
          staticClass: "JmY-lOo3h5Cbi JmY-_k02XzxzV",
          attrs: { elementtiming: "element-timing" },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "JmY-lOo3h5Cbi JmY-jSKxLcgUE",
          attrs: { elementtiming: "element-timing" },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "JmY-lOo3h5Cbi JmY-Ani5Jfyni",
          attrs: { elementtiming: "element-timing" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "JmY-LMXZHpeRN" }, [
      _c("span", {
        staticClass: "leads-popper-trigger leads-popper-trigger-hover flex",
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "leads-popper-trigger leads-popper-trigger-hover leads-tooltip",
        },
        [_c("div", { staticClass: "JmY-GtMNhXCKg JmY-wkRt8TvIq" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }