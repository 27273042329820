"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  type1: '类型',
  type1_1: '型号',
  speci1: '规格',
  pric1: '价格',
  number: '数量'
};
var _default = languagePack;
exports.default = _default;