"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
var user = {
  state: {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    roles: [],
    id: [],
    permissions_button: [],
    userInfo: {},
    sort: {},
    lastSort: {
      time: null,
      sort: {}
    }
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
      state.userInfo.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions_button = permissions;
    },
    SET_USER_INFO: function SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_PREFERENCE: function SET_PREFERENCE(state, obj) {
      console.log('%c 🥩 SET_PREFERENCE: ', 'font-size:20px;background-color: #3F7CFF;color:#fff;', obj);
      if (obj) {
        state.sort = obj;
      }
    },
    SET_SORT: function SET_SORT(state, array) {
      console.log("%c 🌮 array", "color:#2eafb0", array);
      state.sort[array[0]] = array[1];
      state.lastSort.sort = state.sort;
      state.lastSort.time = new Date().getTime();
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      // const username = userInfo.username.trim()
      var username = userInfo.account;
      var avatar = require("@/assets/image/setting.png");
      console.log('%c 🍰 username: ', 'font-size:20px;background-color: #465975;color:#fff;', username);
      var password = userInfo.password;
      // const code = userInfo.code
      // const uuid = userInfo.uuid
      return new Promise(function (resolve, reject) {
        (0, _login.login)(username, password).then(function (res) {
          if (res.code == 1) {
            (0, _auth.setToken)(res.data.token);
            commit('SET_TOKEN', res.data.token);
            (0, _auth.setname)(username);
            commit('SET_NAME', username);
            commit('SET_AVATAR', avatar);
            resolve();
          } else {
            reject(res.msg);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var dispatch = _ref2.dispatch,
        commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.getInfo)().then(function (res) {
          var avatar = require("@/assets/image/setting.png");
          if (res.code == 1) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_AVATAR', avatar);
            commit('SET_ROLES', res.data.roles);
            commit('SET_USER_INFO', res.data.user_info);
            commit('SET_PERMISSIONS', res.data.button_mark);
            try {
              var sort = JSON.parse(res.data.user_info.preference);
              commit('SET_PREFERENCE', sort);
            } catch (err) {
              console.error('SET_PREFERENCE ERROR', err);
            }
          }
          // else {
          //   commit('SET_ROLES', ['ROLE_DEFAULT'])
          // }
          // commit('SET_NAME', user.userName)
          // commit('SET_AVATAR', avatar)
          resolve(res);
        }).catch(function (error) {
          console.log('cuowu', error);
          reject(error);
        });
      });
    },
    // 获取通话系统参数
    loginCallCenter: function loginCallCenter(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.loginCallCenter)().then(function (res) {
          console.log("%c Line:143 🍔 loginCallCenter", "color:#93c0a4", res.data);
          resolve(res.data);
        }).catch(function (error) {
          console.log('cuowu', error);
          reject(error);
        });
      });
    },
    // 退出系统
    LogOut: function LogOut(_ref4) {
      var commit = _ref4.commit,
        state = _ref4.state;
      return new Promise(function (resolve, reject) {
        // logout(state.token).then(() => {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        commit('SET_PERMISSIONS', []);
        (0, _auth.removeToken)();
        resolve();
        // }).catch(error => {
        //   reject(error)
        // })
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        resolve();
      });
    },
    // 保存排序偏好
    AutoSavePreference: function AutoSavePreference(_ref6) {
      var commit = _ref6.commit,
        state = _ref6.state;
      // console.log('%c 🍾 AutoSavePreference: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;');
      // if(!state.token) {
      //   console.log('AutoSavePreference','用户尚未登录')
      //   return
      // }
      // if(!state.lastSort.time) {
      //   console.log('AutoSavePreference','无更新')
      //   return
      // }

      // let now = new Date().getTime()
      // let last = state.lastSort.time
      // if(now - last > 60 * 1000 && !_.isEqual(state.sort, state.lastSort.sort)){
      //   if(state.sort){
      (0, _login.savePreference)(JSON.stringify(state.sort));
      //     }else{
      //       console.log('AutoSavePreference','排序数据不存在')
      //       return
      //     }
      //   }else{
      //     console.log('AutoSavePreference','距离上次保存不超过60秒')
      //   }
    }
  }
};
var _default = user;
exports.default = _default;