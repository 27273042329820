"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "doubleScroll",
  data: function data() {
    var _ref;
    return _ref = {
      width: 0,
      intervaler: 0
    }, (0, _defineProperty2.default)(_ref, "intervaler", 2), (0, _defineProperty2.default)(_ref, "ele", null), (0, _defineProperty2.default)(_ref, "vxe", null), (0, _defineProperty2.default)(_ref, "num", Math.floor(Math.random() * 100 * Math.random() * 100 + 10000)), _ref;
  },
  props: {
    elementId: {
      type: String,
      default: ''
    },
    margin: {
      type: Number,
      default: 0
    }
  },
  methods: {
    selfCheck: function selfCheck() {
      var ele = document.getElementById("doubleScroll-".concat(this.num));
      if (ele) {
        console.log("%c Line:36 🥚 ele", "color:#4fff4B", ele.offsetTop);
      }
    },
    handleScroll: function handleScroll(e) {
      // console.log("%c Line:32 🍒 handleScroll", "color:#7f2b82", this.vxe);
      if (this.vxe) {
        this.vxe.scrollLeft = this.ele.scrollLeft;
        this.$forceUpdate();
      }
    },
    checkChildren: function checkChildren() {
      var ele = document.getElementById("".concat(this.elementId));
      // console.log("%c Line:25 🍓 ele", "color:#2eafb0", `#${this.elementId}`, ele);
      if (!ele) {} else {
        this.ele = document.getElementById("doubleScroll-".concat(this.num));
        var width = document.querySelector("#".concat(this.elementId, " .vxe-table--empty-block")).style.width;
        this.width = parseInt(width);
      }
    },
    bindScroll: function bindScroll() {
      var _this = this;
      var vxe = document.querySelector('.vxe-table--body-wrapper.body--wrapper');
      if (!vxe) {
        setTimeout(function () {
          _this.bindScroll();
        }, 300);
        return;
      } else {
        this.vxe = vxe;
        vxe.addEventListener('scroll', function (e) {
          if (_this.ele) {
            _this.ele.scrollLeft = vxe.scrollLeft;
            _this.$forceUpdate();
          }
        });
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.intervaler = setInterval(function () {
      _this2.checkChildren();
    }, 300);
    setTimeout(function () {
      clearInterval(_this2.intervaler);
    }, 10 * 1000);
    // this.intervaler2 = setInterval(() => {
    //   this.selfCheck()
    // }, 300)
    this.bindScroll();
  },
  unmounted: function unmounted() {
    clearInterval(this.intervaler);
    clearInterval(this.intervaler2);
  }
};
exports.default = _default;