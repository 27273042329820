"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  button1: "添加搜索项",
  button2: "还原条件",
  tabletitle1: "全选",
  tabletitle2: "名称",
  confirmtitle: "您确定要还原数据吗?",
  confirmtitle1: "提示"
};
var _default = languagePack;
exports.default = _default;