"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = _interopRequireDefault(require("./auth"));
var _cache = _interopRequireDefault(require("./cache"));
var _modal = _interopRequireDefault(require("./modal"));
var _download = _interopRequireDefault(require("./download"));
var _default = {
  install: function install(Vue) {
    // 认证对象
    Vue.prototype.$auth = _auth.default;
    // 缓存对象
    Vue.prototype.$cache = _cache.default;
    // 模态框对象
    Vue.prototype.$modal = _modal.default;
    // 下载文件
    Vue.prototype.$download = _download.default;
  }
};
exports.default = _default;