exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuColor": "#bfcbd9",
	"menuLightColor": "rgba(0,0,0,0.7)",
	"menuColorActive": "#f4f4f5",
	"menuBackground": "#304156",
	"menuLightBackground": "#fff",
	"subMenuBackground": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "200px",
	"logoTitleColor": "#fff",
	"logoLightTitleColor": "#001529"
};