"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  buttonyi: "添加产品一级分类",
  buttondaoru: "导入分类",
  buttonjuese: "相关自定义角色设置",
  buttonrizhi: "类别规则日志",
  tabletitleyi: "一级分类",
  tabletitleer: "二级分类",
  tabletitlesan: "三级分类",
  dialogtitleji: "基本信息",
  dialoginputfu: "父级类别",
  dialoginput2: "父级类别",
  dialoginput3: "父级类别",
  formitem2: "类别英文名",
  inputplaceholder2: "请输入类别英文名",
  formitem3: "类别中文名",
  inputplaceholder3: "请输入类别中文名",
  formitem4: "是否锁定",
  formradio1: "是",
  formradio2: "否",
  formitem5: "排序",
  inputplaceholder4: "请输入排序",
  formitem6: "是否激活",
  buttonsubmit: "确 定",
  buttonclone: "取 消",
  labelxiazai: "下载",
  label2: "下载标准格式表格（更新日期 2021-04-27）",
  buttondown: "下载表格",
  labeltishi: "（如果点击后没有反应，请检查浏览器是否屏蔽了弹窗功能）",
  labelup: "上传",
  label3: "请确认无误后上传",
  buttonup: "上传文件",
  ptishi: "不要随意修改Excel模板格式，否则可能导致批量操作失败",
  ptishi2: "请完全依照下载模板的格式填入信息，不要使用函数、算法等操作Excel",
  ptishi3: "否则可能导致读取的数值和实际不符",
  tablejuese: "产品角色",
  tableNumber: "已授权员工数量",
  tablecaozuo: "操作",
  formitem7: "授权操作人",
  formitem8: "更新当前目录下的下级目录",
  check1: "是",
  buttonbaocun: "保存",
  activatedate: "开始日期",
  enddate: "结束日期",
  inputplaceholder5: "搜索日志内容",
  buttonsousuo: "查询",
  tablebianhao: "编号",
  tablecaozuorizhi: "操作日志",
  tablecaozuoren: "操作人",
  tablecaozuoshijian: "操作时间",
  yanzhen1: "英文名称不能为空",
  yanzhen2: "中文名称不能为空",
  yanzhen3: "是否锁定不能为空",
  yanzhen4: "排序不能为空",
  yanzhen5: "是否激活不能为空",
  dialogtitle1: "新增二级分类",
  dialogtitle2: "新增三级分类",
  confirmneirong: "此操作将永久删除该分类, 是否继续?",
  confirmtitle: "提示",
  confirmqueding: "确定",
  confirmquxiao: "取消",
  messagedel: "删除成功!",
  messagequxiao: "已取消删除",
  dialogtitle3: "编辑一级分类",
  dialogtitle4: "编辑二级分类",
  dialogtitle5: "编辑三级分类",
  messageadd: "新增成功!",
  messageupdate: "编辑成功!",
  delqueren: "是否确认删除参数编号为",
  delshuju: "的数据项?",
  msgdown: "上传文件只能是 excel/xls 格式!",
  addtitle: "添加产品分类",
  downtitle: "分类批量导入",
  juesetitle: "相关自定义角色设置",
  logtitle: "类别规则日志",
  zidingyititle: "分配自定义产品角色人员",
  msgsave: "保存成功!"
};
var _default = languagePack;
exports.default = _default;