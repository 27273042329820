"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  spantitle: "添加组合SKU",
  tabletitle1: "产品SKU",
  tabletitle2: "自定义SKU",
  tabletitle3: "图片",
  tabletitle4: "数量",
  tabletitle5: "产品名称",
  tabletitle6: "产品价格",
  tabletitle7: "添加时间",
  tabletitle8: "操作",
  tableplaceholder: "请输入内容",
  buttonsave: "保存并继续编辑",
  buttonclone: "关闭窗口"
};
var _default = languagePack;
exports.default = _default;