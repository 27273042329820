"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  buttonaddtitle: "添加新词",
  buttonlogtitle: "批量导入",
  inputplaceholder: "请输入关键字",
  starttime: "开始日期",
  endtime: "结束日期",
  selectplaceholder: "类型",
  selectplaceholder2: "风险等级",
  buttonsousuo: "查询",
  buttonchongzhi: "重置",
  selectplaceholder3: "批量操作",
  buttonsubmit: "提交",
  tableguanjian: "关键词",
  tabledesc: "备注",
  tabletype: "类型",
  tablelevel: "风险等级",
  tablename: "添加人",
  tablecreatetime: "更新时间",
  tablecaozuo: "操作",
  formitemlabel1: "关键词",
  inputplaceholder2: "请输入关键词",
  formitemlabel2: "备注",
  inputplaceholder3: "请输入备注",
  formitemlabel3: "类别",
  inputplaceholder4: "请选择类别",
  formitemlabel4: "风险等级",
  inputplaceholder5: "请选择风险等级",
  buttonsave: "保存",
  buttonclone: "取消",
  yanzhen1: "关键词不能为空",
  yanzhen2: "备注不能为空",
  yanzhen3: "类别不能为空",
  yanzhen4: "风险等级不能为空",
  guolv1: "普通",
  guolv2: "高危",
  dialogadd: "添加新词",
  msgupdate: "修改成功",
  msgupdatesb: "修改失败：",
  msgxinzeng: "新增成功",
  msgxinzengsb: "新增失败：",
  uptitle: "批量导入",
  msgerror: "请选择要操作的数据",
  confirmneirong: "是否确认删除参数编号为",
  confirmneirong2: "的数据项",
  confirmbutton1: "确定",
  confirmbutton2: "取消",
  confirmtitle: "警告",
  msgSuccess: "删除成功",
  dialogupdate: "修改新词",
  alertneirong: "提交成功！",
  alerttitle: "提示",
  buttontitle: "编辑",
  buttontitle1: "删除",
  option1: "商标logo",
  option2: "禁用词（如歧视性词语）",
  option3: "禁售品",
  option4: "知名品牌",
  option5: "常用词",
  option6: "销售自提禁用词",
  option7: "普通",
  option8: "高危",
  selectplaceholder4: "添加人"
};
var _default = languagePack;
exports.default = _default;