"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  buttonaddwuliu: "新增物流包材",
  buttonbatchdel: "批量删除",
  tabletype: "类型",
  tablemodel: "型号",
  tablelength: "长",
  tablewidth: "宽",
  tableheight: "高",
  tablevolume: "体积",
  tableweight: "重量",
  tableprice: "价格",
  tablecreatetime: "添加时间",
  selectplaceholder: "请选择物流包材所属类别",
  formitemtype: "类别",
  formitemmodel: "型号",
  formitemlength: "长",
  formitemwidth: "宽",
  formitemheight: "高",
  formitemweight: "重量",
  formitemvolume: "体积",
  formitemprice: "价格",
  formitemremark: "备注",
  buttonsave: "保存",
  guolv1: "纸箱",
  guolv2: "信封箱",
  guolv3: "气泡信封袋",
  guolv4: "PE快递袋",
  guolv5: "自定义",
  yanzhen: "请选择包材类型",
  dialogaddtitle: "添加物流包材",
  msgaddcg: "新增成功!",
  msgaddsb: "新增失败！",
  confirmneirong: "请选择需要删除的编号后再进行删除操作！",
  confirmtitle: "提示",
  confirmqueding: "确定",
  confirmquxiao: "取消",
  confirmneirong1: "此操作将永久删除当前数据, 是否继续?",
  msgdel1: "删除成功！",
  msgdel2: "删除失败!",
  msgdel3: "已取消删除",
  formitemtype1: "类别:",
  formitemmodel1: "型号:",
  formitemlength1: "长:",
  formitemwidth1: "宽:",
  formitemheight1: "高:",
  formitemweight1: "重量:",
  formitemvolume1: "体积:",
  formitemprice1: "价格:",
  formitemremark1: "备注:"
};
var _default = languagePack;
exports.default = _default;