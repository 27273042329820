"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
_axios.default.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
var request = _axios.default.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 5 * 60 * 1000
});
// request拦截器
request.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['x-auth-token'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers['Access-Token'] = 'YJGzmoKjkwGFsUeUAVxbFWHWTsxOMugA';
  console.log('111', config.url);
  var url = localStorage.getItem('baseUrl1');
  if (url) {
    config.url = url + config.url;
  }
  return config;
}, function (error) {
  console.log(error);
  Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use(function (res) {
  var code = res.data.code;
  console.log('响应拦截器 res:', res);
  if (code === 401) {
    _elementUi.MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
  } else if (code !== 1) {
    return Promise.reject(res.data);
  } else if (code <= 0) {
    return Promise.reject(res.data);
  } else {
    return res.data;
  }
}, function (error) {
  console.log('err', error.name, error.message);
  (0, _elementUi.Message)({
    message: error.message == 'timeout of 10000ms exceeded' ? '网络连接超时(> 10s)' : error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var axiosInstance = function axiosInstance(_ref) {
  var url = _ref.url,
    method = _ref.method,
    params = _ref.params,
    data = _ref.data;
  return new Promise(function (resolve, reject) {
    request({
      url: url,
      method: method,
      params: cleanObj(params),
      data: cleanObj(data)
    }).then(function (res) {
      if (res.code == 1) {
        resolve(res);
      } else {
        _elementUi.Notification.error(res.msg);
        console.error("".concat(method, " ").concat(url, " failed!"), res);
        resolve({});
      }
    }, function (rej) {
      console.log("%c Line:91 🍿 rej", "color:#f5ce50", rej);
      _elementUi.Notification.error(rej.msg || rej.message);
      console.error("".concat(method, " ").concat(url, " failed!"), rej);
      reject(rej);
    }).catch(function (err) {
      console.log("%c Line:95 🌽 err", "color:#465975", err);
      _elementUi.Notification.error("服务器开小差啦");
      reject(err);
    });
  });
};
var cleanObj = function cleanObj(obj) {
  var priorityList = ['make_invoice_img', 'pay_voucher'];
  if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      if (obj[key] === undefined || obj[key] === '') {
        !priorityList.includes(key) && delete obj[key];
      }
    });
    var arrayKeys = [];
    for (var key in obj) {
      if (obj[key] instanceof Array && key != 'custom') {
        obj[key] = encodeURIComponent(JSON.stringify(obj[key]));
        arrayKeys.push(key);
      }
    }
    if (arrayKeys.length > 0) {
      obj.arrayKeys = arrayKeys.join(',');
    }
    return obj;
  }
  return null;
};
var _default = axiosInstance;
exports.default = _default;