"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title1: '文件资料存档',
  button1: '上传文件',
  label1: '文件名',
  label2: '操作人',
  label3: '操作时间',
  label4: '操作记录',
  button2: '删除',
  txt1: "*提示：格式支持jpg，png，gif，doc，docx，xlsx，xls，pdf"
};
var _default = languagePack;
exports.default = _default;