"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'imgUpload',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      fileList: [],
      uploadAction: process.env.VUE_APP_BASE_API + "CommonFunc/uploads",
      uploadHeader: {
        "x-auth-token": (0, _auth.getToken)(),
        "Access-Token": "YJGzmoKjkwGFsUeUAVxbFWHWTsxOMugA"
      },
      imageUrl: ''
    };
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(response, file, fileList) {
      console.log("%c Line:33 🍖 response", "color:#4fff4B", response);
      console.log("%c Line:33 🥔 fileList", "color:#2eafb0", fileList);
      if (response.code == 1) {
        this.fileList = fileList;
        this.imageUrl = response.data[0].url;
        this.$emit('input', response.data[0].url);
      }
      console.log("%c Line:33 🥔 this.fileList", "color:#2eafb0", this.fileList);
    }
  },
  watch: {
    value: function value(newValue) {
      console.log("%c Line:44 🍌 newValue", "color:#e41a6a", newValue);
      if (newValue) {
        this.fileList = [{
          url: newValue
        }];
        this.imageUrl = newValue;
      } else {
        this.fileList = [];
        this.imageUrl = '';
      }
    }
  }
};
exports.default = _default;