"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  txt1: "产品规格参数",
  label1: "颜色:",
  label2: "尺码:",
  checkbox1: "鞋子尺码",
  placeholder1: "请输入尺寸",
  buttontitle: "确定",
  buttontitle1: "新增",
  tableitem1: "产品SKU",
  tableitem2: "操作",
  buttontitle2: "保存并继续编辑",
  buttontitle3: "关闭窗口",
  placeholder2: "请输入属性名称",
  msgerror: "请选择要输入的尺寸",
  msgerror1: "请补充完所有已有的属性",
  confirmtitle1: "此操作将删除该产品, 是否继续?",
  confirmtitle2: "提示",
  confirmbutton1: "确定",
  confirmbutton2: "取消",
  msgerror2: "删除成功!",
  msgerror3: "已取消删除"
};
var _default = languagePack;
exports.default = _default;