"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getKeywordList = getKeywordList;
exports.getTagList = getTagList;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取运营素材标签列表接口
function getTagList(params) {
  return (0, _request.default)({
    url: "/getMaterialsTag",
    method: "get",
    params: params
  });
}

// 获取运营关键词列表接口
function getKeywordList(params) {
  return (0, _request.default)({
    url: "/OperationKeyword",
    method: "get",
    params: params
  });
}