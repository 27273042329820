"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title1: '财务资料',
  title2: '添加',
  label1: '默认结算账户',
  label2: '收款银行',
  label3: '收款人/公司',
  label4: '收款银行地址',
  label5: '银行账号',
  label6: '收款人身份证',
  label7: '结算货币',
  label8: '是否退税账户',
  label9: '操作',
  button1: '编辑',
  button2: '删除',
  label10: '默认结算账户',
  checkbox1: '默认',
  button3: '保 存',
  button4: '取 消',
  label11: '一般纳税人税票:',
  radio1: '开具发票',
  radio2: '不开具发票',
  label12: '票据类型:',
  placeholder12: '请选择',
  label13: '开票人资质:',
  label14: '开票人类型:',
  label15: '开票所在地:',
  label16: '增值税率:',
  label17: '结算时间:',
  label18: '付款起始日:',
  placeholder18: '选择日期',
  label19: '法人身份证:',
  filter1: '否',
  filter2: '是',
  data1_1: '增值税发票',
  data1_2: '普通发票',
  data1_3: '收据',
  data1_4: '其他',
  data2_1: '一般纳税人认定满2年',
  data2_2: '一般纳税人认定不满2年',
  data2_3: '小规模',
  data3_1: '生产型号',
  data3_2: '贸易型',
  data4_1: '按下单时间',
  data4_2: '按销单时间',
  data4_3: '按入库时间',
  data5_1: '测试数据',
  message1: '请输入付款起始日',
  message2: '你的身份证格式不正确',
  message3: '请输入银行账号',
  title3: '添加银行账户',
  title4: '修改银行账户'
};
var _default = languagePack;
exports.default = _default;