"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = require("@/api/system/config");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "uploadPic",
  data: function data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 参数表格数据
      configList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 类型数据字典
      typeOptions: [],
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        configName: undefined,
        configKey: undefined,
        configType: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        configName: [{
          required: true,
          message: "参数名称不能为空",
          trigger: "blur"
        }],
        configKey: [{
          required: true,
          message: "参数键名不能为空",
          trigger: "blur"
        }],
        configValue: [{
          required: true,
          message: "参数键值不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    /** 查询参数列表 */getList: function getList() {
      var _this = this;
      this.loading = true;
      // 假想接口返回数据
      var response = {
        total: 3,
        rows: [{
          configId: '1',
          configName: '测试数据',
          configKey: '测试数据',
          configValue: '测试数据',
          configType: '测试数据',
          remark: '测试数据',
          createTime: '2021年06月23日16:51:15'
        }, {
          configId: '2',
          configName: '测试数据',
          configKey: '测试数据',
          configValue: '测试数据',
          configType: '测试数据',
          remark: '测试数据',
          createTime: '2021年06月23日16:51:15'
        }, {
          configId: '3',
          configName: '测试数据',
          configKey: '测试数据',
          configValue: '测试数据',
          configType: '测试数据',
          remark: '测试数据',
          createTime: '2021年06月23日16:51:15'
        }]
      };
      // listConfig(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
      this.configList = response.rows;
      this.total = response.total;
      setTimeout(function () {
        _this.loading = false;
      }, 1000); // 模拟请求时间，接入接口后设置定时为0

      // });
    },
    // 取消按钮
    cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset: function reset() {
      this.form = {
        configId: undefined,
        configName: undefined,
        configKey: undefined,
        configValue: undefined,
        configType: "Y",
        remark: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加参数";
    },
    // 多选框选中数据
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.configId;
      });
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 修改按钮操作 */handleUpdate: function handleUpdate(row) {
      this.reset();
      var configId = row.configId || this.ids;
      // 拉取单条信息详情后，展示弹窗以修改
      var response = {
        data: {
          configId: '1',
          configName: '测试数据',
          configKey: '测试数据',
          configValue: '测试数据',
          configType: '测试数据',
          remark: '测试数据',
          createTime: '2021年06月23日16:51:15'
        }
      };
      // getConfig(configId).then(response => {
      this.form = response.data;
      this.open = true;
      this.title = "修改参数";
      // });
    },

    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this2 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this2.form.configId != undefined) {
            // 修改
            (0, _config.updateConfig)(_this2.form).then(function (response) {
              if (response.code === 200) {
                _this2.msgSuccess("修改成功");
                _this2.open = false;
                _this2.getList();
              } else {
                _this2.msgError('修改失败：' + response.msg);
              }
            });
          } else {
            // 新增
            (0, _config.addConfig)(_this2.form).then(function (response) {
              if (response.code === 200) {
                _this2.msgSuccess("新增成功");
                _this2.open = false;
                _this2.getList();
              } else {
                _this2.msgError('新增失败：' + response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */handleDelete: function handleDelete(row) {
      var _this3 = this;
      var configIds = row.configId || this.ids;
      this.$confirm('是否确认删除参数编号为"' + configIds + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _config.delConfig)(configIds);
      }).then(function () {
        _this3.getList();
        _this3.msgSuccess("删除成功");
      }).catch(function () {});
    },
    /** 导出按钮操作 */handleExport: function handleExport() {
      var _this4 = this;
      var queryParams = this.queryParams;
      this.$confirm('是否确认导出所有参数数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _config.exportConfig)(queryParams);
      }).then(function (response) {
        _this4.download(response.msg);
      }).catch(function () {});
    }
  }
};
exports.default = _default;