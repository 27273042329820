"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  txt1: "操作人员信息",
  label1: "添加人员:",
  label2: "开发人员:",
  label3: "采购人员:",
  label4: "采购经理:",
  placeholder1: "请选择开发人员",
  placeholder2: "请选择采购人员",
  placeholder3: "请选择采购经理",
  buttontitle: "保存"
};
var _default = languagePack;
exports.default = _default;