"use strict";

var _interopRequireDefault = require("/mnt/data/websites/erp-vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
// components/index.js
// 导入并导出全局组件

// 自动加载 global 目录下的 .vue 结尾的文件
var componentsContext = require.context('./global', true, /\.js$/);
componentsContext.keys().forEach(function (component) {
  var componentConfig = componentsContext(component);
  /**
  * 兼容 import export 和 require module.export 两种规范
  */
  var ctrl = componentConfig.default || componentConfig;
  _vue.default.component(ctrl.name, ctrl);
  // console.log(ctrl.name)
});