"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "hint",
  computed: {
    // ...mapState({
    //   showTip: state => state.showTip
    // }),
  },
  props: {
    text: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      showTip: true
    };
  }
};
exports.default = _default2;