"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  gradeColor: [] //客户等级颜色
};

var mutations = {
  // 保存随机的颜色，避免每次刷新都随机
  saveColor: function saveColor(state, color) {
    state.gradeColor = color;
  }
};
var actions = {};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;