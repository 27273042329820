"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var languagePack = {
  title1: '基本信息',
  label1: '供应商编号:',
  span1: '系统自动生成',
  label2: '采购类型:',
  placeholder1: '采购类型',
  label3: '付款方式:',
  placeholder2: '付款方式',
  label4: '供应商名称:',
  label5: '邮编:',
  label6: '税务登记号:',
  label7: '主营品类:',
  label8: '供应商地址:',
  label9: '供应商发货地:',
  placeholder9: '选择省份',
  placeholder9_1: '选择城市',
  label10: '对应仓库:',
  placeholder10: '本地中国',
  save: '保存',
  data1_1: '网络采购',
  data1_2: '工厂采购',
  data1_3: '市场采购',
  data1_4: '自主生产',
  data1_5: '未知采购类型',
  data2_1: '现金付款',
  data2_2: '网络在线付款',
  data2_3: '转账付款',
  data2_4: '阿里账期付款',
  data3_1: '测试数据',
  message1: '请输入供应商名称',
  message2: '请输入供应商地址'
};
var _default = languagePack;
exports.default = _default;