"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _customField = require("@/api/customField");
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "PrimaryCustomField",
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: function value(newVal, oldVal) {
      this.selectValue = newVal;
    }
  },
  data: function data() {
    return {
      title: {
        1: '线索来源',
        2: '成交方式',
        3: '采购类型',
        4: '客户等级',
        5: '供应商类型',
        6: '线索状态',
        7: '单位',
        8: '商机状态',
        9: '线索等级',
        10: '跟进方式',
        11: '结算账户',
        12: '付款方式',
        13: '收款方式',
        14: '客户类别',
        15: '销售类型',
        16: '失败原因',
        17: '运营账号'
      },
      typeOptions: [],
      selectValue: undefined
    };
  },
  props: {
    type: {
      type: Number,
      default: ''
    },
    value: {
      type: String
    },
    customTitle: {
      type: String,
      default: ''
    },
    customProp: {
      type: String,
      default: 'type'
    }
  },
  methods: {
    handleValueChange: function handleValueChange(e) {
      this.selectValue = e;
      this.$emit('change', e);
    },
    getOptions: function getOptions() {
      var _this = this;
      (0, _customField.getSelectList)({
        type: this.type
      }).then(function (res) {
        _this.typeOptions = res.data[0].value;
      }).catch(function (err) {
        console.error(err);
      });
    }
  },
  mounted: function mounted() {
    this.getOptions();
    this.selectValue = this.value;
  }
};
exports.default = _default;