"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var loadingInstance;
var _default = {
  // 消息提示
  msg: function msg(content) {
    _elementUi.Message.info(content);
  },
  // 错误消息
  msgError: function msgError(content) {
    _elementUi.Message.error(content);
  },
  // 成功消息
  msgSuccess: function msgSuccess(content) {
    _elementUi.Message.success(content);
  },
  // 警告消息
  msgWarning: function msgWarning(content) {
    _elementUi.Message.warning(content);
  },
  // 弹出提示
  alert: function alert(content) {
    _elementUi.MessageBox.alert(content, "系统提示");
  },
  // 错误提示
  alertError: function alertError(content) {
    _elementUi.MessageBox.alert(content, "系统提示", {
      type: 'error'
    });
  },
  // 成功提示
  alertSuccess: function alertSuccess(content) {
    _elementUi.MessageBox.alert(content, "系统提示", {
      type: 'success'
    });
  },
  // 警告提示
  alertWarning: function alertWarning(content) {
    _elementUi.MessageBox.alert(content, "系统提示", {
      type: 'warning'
    });
  },
  // 通知提示
  notify: function notify(content) {
    _elementUi.Notification.info(content);
  },
  // 错误通知
  notifyError: function notifyError(content) {
    _elementUi.Notification.error(content);
  },
  // 成功通知
  notifySuccess: function notifySuccess(content) {
    _elementUi.Notification.success(content);
  },
  // 警告通知
  notifyWarning: function notifyWarning(content) {
    _elementUi.Notification.warning(content);
  },
  // 确认窗体
  confirm: function confirm(content) {
    return _elementUi.MessageBox.confirm(content, "系统提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: "warning"
    });
  },
  // 打开遮罩层
  loading: function loading(content) {
    loadingInstance = _elementUi.Loading.service({
      lock: true,
      text: content,
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
  },
  // 关闭遮罩层
  closeLoading: function closeLoading() {
    loadingInstance.close();
  }
};
exports.default = _default;